import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from './middleware/logger';
import { createTracker } from 'redux-segment';
import analyticActionMapper from './middleware/analyticsActionMapper';
import rootReducer from '@/redux/rootReducer';

/**
 * Creates a redux store
 */
type CreateStoreParams = { initialData: any; isTesting?: boolean };
export const createStore = ({ initialData, isTesting = false }: CreateStoreParams) => {
    // middlewares
    const middleware: any[] = [];

    if (!isTesting) {
        if (__DEVELOPMENT__) {
            middleware.push(createLogger({ client: false, server: false }));
        } else {
            middleware.push(createTracker(analyticActionMapper));
        }
    }

    return configureStore({
        devTools: __DEVELOPMENT__ || process.env.DEPLOYMENT !== 'prod',
        middleware: (getDefaultMiddleware) =>
            // Add the guaranteed history, then add the middleware that's dynamically generated.
            // It's currently all disabled because our redux is incredibly broken as of now.
            // A prepend then concat is placed like this to maintain the order of previous middleware.
            // TODO: tl;dr we want to remove these two flags, as it will add performance gains
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).concat(middleware),
        preloadedState: initialData,
        reducer: rootReducer(),
    });
};
