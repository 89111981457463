import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category Search API
 * @see trackClick
 */
type PostTrackClickParams = {
    algoliaAnalytics: {
        queryId: string;
        usedIndex: string;
    };
    bidderId: number;
    deployment: string;
    ipAddress: string;
    itemId: number;
    position: number;
};
/**
 * @category Search API
 * @see trackClick
 * @see trackConversion
 */
interface PostClickConversionResponse {
    error: boolean;
    payload: string;
}
/**
 * trackClick - sends click tracking event to algolia
 * @category Search API
 * @function trackClick
 * @param {PostTrackClickParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/clickconversion
 */
function trackClick({ algoliaAnalytics, bidderId, deployment, ipAddress, itemId, position }: PostTrackClickParams) {
    return new Promise<PostClickConversionResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'clickconversion',
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });
        request.send({
            itemId,
            position,
            queryId: algoliaAnalytics.queryId,
            usedIndex: algoliaAnalytics.usedIndex,
            userId: bidderId,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
}

/**
 * @category Search API
 * @see trackConversion
 */
type PostTrackConversionParams = {
    algoliaAnalytics: {
        queryId: string;
        usedIndex: string;
    };
    bidderId: number;
    deployment: string;
    ipAddress: string;
    itemId: number;
};
/**
 * trackConversion - sends conversion tracking event to algolia
 * @category Search API
 * @function trackConversion
 * @param {PostTrackConversionParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/clickconversion
 */
function trackConversion({ algoliaAnalytics, bidderId, deployment, ipAddress, itemId }: PostTrackConversionParams) {
    return new Promise<PostClickConversionResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'clickconversion',
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });
        request.send({
            itemId,
            queryId: algoliaAnalytics.queryId,
            usedIndex: algoliaAnalytics.usedIndex,
            userId: bidderId,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
}

export default {
    trackClick,
    trackConversion,
};
