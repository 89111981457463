import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { ApiHosts, handleResponseWithNon200Errors, makeGet, makePost } from './helpers';
import { BankAccount } from '@liveauctioneers/caterwaul-components/types/BankAccount';
import { BidderAddress } from '@/types/BidderAddresses';
import { CreditCard } from '@liveauctioneers/caterwaul-components/types/CreditCard';
import { PaymentMethods, PaymentProviders } from '@/enums';

/**
 * @category Payments API
 * @see postAddLACard
 */
type PostAddLACardParams = {
    authToken: string;
    bidderId: number;
    billingAddress: Address;
    cardNonce: string;
    cardholderName: string;
    deployment: string;
    houseId: number;
    providerId: number;
};
/**
 * postAddLACard - adds a credit card to for Payrix and Paysafe's card-on-file for bidder
 * @category Payments API
 * @function postAddLACard
 * @param {PostAddLACardParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/cards/
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/upmpo6q7pc/resources/72dull/methods/POST
 * @see https://app.swaggerhub.com/apis/LiveAuctioneers/payment-redstripe/1.0.0#/default/post_cards__bidderId_
 */
export const postAddLACard = ({
    authToken,
    bidderId,
    billingAddress,
    cardholderName,
    cardNonce,
    deployment,
    houseId,
    providerId,
}: PostAddLACardParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `cards/${bidderId}/add`,
            authToken,
            deployment,
            forceCredentials: true,
            path: ApiHosts.Redstripe,
        });
        request.send({
            billingAddress,
            cardholderName,
            cardNonce,
            defaultCard: true,
            houseId,
            providerId,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see postAddQuadypayCard
 */
type PostAddZipCardParams = {
    authToken: string;
    bidderId: number;
    billingAddress: Address;
    brand: string;
    deployment: string;
    firstName: string;
    houseId: number;
    lastName: string;
    month: number;
    number: string;
    providerId: number;
    securityCode: string;
    year: string;
};
/**
 * postAddZipCard - adds a single use credit card to use for a bidder.
 * Quadpay had a rebrand, so the front end has all been renamed to zip
 * @category Payments API
 * @function postAddZipCard
 * @param {PostAddZipCardParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/cards/{bidderId}/quadpay
 */
export const postAddZipCard = ({
    authToken,
    bidderId,
    billingAddress,
    brand,
    deployment,
    firstName,
    houseId,
    lastName,
    month,
    number,
    providerId,
    securityCode,
    year,
}: PostAddZipCardParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `cards/${bidderId}/quadpay`,
            authToken,
            deployment,
            path: ApiHosts.Payment,
        });
        request.send({
            billingAddress,
            brand,
            firstName,
            houseId,
            lastName,
            month,
            number,
            providerId,
            securityCode,
            year,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see postUpdateLACard
 */
type PostUpdateLACardParams = {
    authToken: string;
    bidderId: number;
    billingAddress?: Address;
    cardId: number;
    defaultCard: boolean;
    deployment: string;
    enabled: boolean;
};
/**
 * postUpdateLACard - updates an existing bidder's card-on-file
 * @category Payments API
 * @function postUpdateLACard
 * @param {PostUpdateLACardParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/bidder/${bidderId}/card
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/upmpo6q7pc/resources/72dull/methods/POST
 * @see https://app.swaggerhub.com/apis/LiveAuctioneers/payment-redstripe/1.0.0#/default/post_bidder__bidderId__card__cardId_
 */
export const postUpdateLACard = ({
    authToken,
    bidderId,
    billingAddress,
    cardId,
    defaultCard,
    deployment,
    enabled,
}: PostUpdateLACardParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/${bidderId}/card/${cardId}`,
            authToken,
            deployment,
            path: ApiHosts.Payment,
        });
        request.send({
            billingAddress,
            defaultCard,
            enabled,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Mainhost API
 * @see postLAPayment
 */
type PostLAPaymentParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    installmentFee: number;
    invoiceId: number;
    paymentMethod: PaymentMethods;
    paymentToken?: string;
    phoneNumber: string;
    riskifiedSessionId?: string;
    shippingAddress: BidderAddress;
    shippingMethod?: string;
    signifydSessionId?: string;
};
/**
 * postLAPayment - posts payment data to mainhost's LA Payments / Payrix API receiver
 * @category Mainhost API
 * @function postLAPayment
 * @param {PostLAPaymentParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/api/bidders/${bidderId}/checkout/${invoiceId}
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php
 */
export const postLAPayment = ({
    authToken,
    bidderId,
    deployment,
    installmentFee,
    invoiceId,
    paymentMethod,
    paymentToken,
    phoneNumber,
    riskifiedSessionId,
    shippingAddress,
    shippingMethod,
    signifydSessionId,
}: PostLAPaymentParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `api/bidders/${bidderId}/checkout/${invoiceId}`,
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
        });
        request.send({
            installmentFee,
            paymentToken,
            paymentType: paymentMethod,
            riskifiedSessionId,
            shippingAddress: {
                address1: shippingAddress.address1,
                address2: shippingAddress.address2,
                address3: shippingAddress.address3,
                addressId: shippingAddress.addressId,
                city: shippingAddress.city,
                countryCode: shippingAddress.countryCode,
                phoneNumber,
                postalCode: shippingAddress.postalCode,
                shippingOptionType: shippingMethod,
                state: shippingAddress.state,
            },
            signifydSessionId,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see getLAPaymentCards
 */
type GetLAPaymentCardsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

type GetLAPaymentCardsResponse = {
    error: boolean;
    payload: CreditCard[];
};
/**
 * getLAPaymentCards - gets a bidder's credit card on file
 * @category Payments API
 * @function getLAPaymentCards
 * @param {GetLAPaymentCardsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/cards/
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/upmpo6q7pc/resources/72dull/methods/GET
 * @see https://app.swaggerhub.com/apis/LiveAuctioneers/payment-redstripe/1.0.0#/default/get_cards__bidderId_
 */
export const getLAPaymentCards = ({ authToken, bidderId, deployment }: GetLAPaymentCardsParams) =>
    new Promise<GetLAPaymentCardsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `cards/${bidderId}`,
            authToken,
            deployment,
            forceCredentials: true,
            path: ApiHosts.Redstripe,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see fetchBankAccounts
 */
type FetchBankAccountsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};
/**
 * fetchBankAccounts - fetches all the enabled bidder bank accounts
 * @category Payments API
 * @function fetchBankAccounts
 * @param {FetchBankAccountsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/bidder/${bidderId}/bankaccounts
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/upmpo6q7pc/resources/h4jba4/methods/GET
 * @see https://app.swaggerhub.com/apis/LiveAuctioneers/payment-redstripe/1.0.0#/default/get_bidder__bidderId__bankaccounts
 */
export const fetchBankAccounts = ({ authToken, bidderId, deployment }: FetchBankAccountsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}/bankaccounts`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see getProcessingFees
 */
type GetProcessingFeesParams = {
    authToken: string;
    balanceDue: number;
    catalogId: number;
    deployment: string;
};
/**
 * getProcessingFees - returns processing fees for a catalog
 * @category Payments API
 * @function getProcessingFees
 * @param {GetProcessingFees}
 * @see https://redstripe-DEPLOYMENT.liveauctioneers.com/payment-api/catalog/${catalogId}/fee
 * @see https://us-east-1.console.aws.amazon.com/ecs/v2/clusters/prod/services/redstripe-prod/tasks?region=us-east-1
 */
export const getProcessingFees = ({ authToken, balanceDue, catalogId, deployment }: GetProcessingFeesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `catalog/${catalogId}/fee`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        request.send({ balanceDue });
        request.end((err, response) => {
            return handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            });
        });
    });

/**
 * @category Payments API
 * @see deleteBankAccount
 */
type DeleteBankAccountParams = {
    authToken: string;
    bankAccountId: number;
    bidderId: number;
    deployment: string;
    plaidAccountId?: string;
};
/**
 * deleteBankAccount - deletes a bank account record by backAccountId and bidderId
 * @category Payments API
 * @function deleteBankAccount
 * @param {DeleteBankAccountParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/bidder/${bidderId}/bankaccounts/${bankAccountId}
 */
export const deleteBankAccount = ({
    authToken,
    bankAccountId,
    bidderId,
    deployment,
    plaidAccountId,
}: DeleteBankAccountParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/${bidderId}/bankaccounts/${bankAccountId}`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        request.send({ plaidAccountId });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see fetchPlaidLinkToken
 */
export type FetchPlaidLinkTokenParams = {
    accessToken?: string;
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type FetchPlaidLinkTokenResponse = {
    error: boolean;
    payload: string;
};

/**
 * fetchPlaidLinkToken - gets a plaid temporary token for a bidder
 * @category Payments API
 * @function fetchPlaidLinkToken
 * @param {FetchPlaidLinkTokenParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/payment/plaid/createlinktoken
 */
export const fetchPlaidLinkToken = ({
    accessToken,
    authToken,
    bidderId,
    deployment,
}: FetchPlaidLinkTokenParams): Promise<FetchPlaidLinkTokenResponse> =>
    new Promise<FetchPlaidLinkTokenResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'plaid/createlinktoken',
            authToken,
            deployment,
            path: ApiHosts.Payment,
        });
        request.send({
            accessToken,
            bidderId,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Payments API
 * @see postCreatePlaidBankAccount
 */
export type PostCreatePlaidBankAccountParams = {
    accessToken?: string;
    accountID: string;
    authToken: string;
    bidderId: number;
    deployment: string;
    firstName: string;
    lastName: string;
    token: string;
};

export type PostCreatePlaidBankAccountResponse = {
    error: boolean;
    payload: BankAccount;
};

/**
 * postCreatePlaidBankAccount - sets a public token for a bidder
 * @category Payments API
 * @function postCreatePlaidBankAccount
 * @param {PostCreatePlaidBankAccountParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/bidder/${bidderId}/bankaccounts/verifiedbyplaid
 */
export const postCreatePlaidBankAccount = ({
    accessToken,
    accountID,
    authToken,
    bidderId,
    deployment,
    firstName,
    lastName,
    token,
}: PostCreatePlaidBankAccountParams): Promise<PostCreatePlaidBankAccountResponse> =>
    new Promise<PostCreatePlaidBankAccountResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/${bidderId}/bankaccounts/verifiedbyplaid`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        request.send({
            accessToken,
            accountID,
            defaultAccount: true,
            firstName,
            lastName,
            providerId: PaymentProviders.Payrix,
            token,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
