import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { GeoArea, RelatedGeoArea } from '@/types/GeoArea';

/**
 * @category Geographic API
 * @see fetchGeoArea
 * */
type FetchGeoAreaParams = {
    deployment: string;
    geoAreaId: number;
};

/**
 * @category Geographic API
 * @see fetchGeoArea
 * */
type FetchGeoAreaResponse = {
    data: GeoArea;
    error: boolean;
};

/**
 * GET request to geographic api service that sends in a geoAreaId and returns data on that geoArea
 * @function fetchGeoArea
 * @category Geographic API
 * @param {FetchGeoAreaParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/geographic/getgeoareabyid?geoAreaId=#
 * @see https://github.com/LIVEauctioneers/geographic/blob/main/internal/services/GeoAreaService.go
 * */
export const fetchGeoArea = ({ deployment, geoAreaId }: FetchGeoAreaParams) =>
    new Promise<FetchGeoAreaResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `geographic/getgeoareabyid?geoAreaId=${geoAreaId}`,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Geographic API
 * @see fetchGeoArea
 * */
type FetchRelatedGeoAreaParams = {
    deployment: string;
    geoAreaId: number;
};

/**
 * @category Geographic API
 * @see fetchGeoArea
 * */
type FetchRelatedGeoAreaResponse = {
    data: RelatedGeoArea[];
    error: boolean;
};

/**
 * GET request to geographic api service that sends in a geoAreaId and returns list of related geoAreas
 * @function fetchRelatedGeoAreas
 * @category Geographic API
 * @param {FetchRelatedGeoAreaParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/geographic/getrelatedgeoareasbyid?geoAreaId=#
 * @see https://github.com/LIVEauctioneers/geographic/blob/main/internal/services/GeoAreaService.go
 * */
export const fetchRelatedGeoAreas = ({ deployment, geoAreaId }: FetchRelatedGeoAreaParams) =>
    new Promise<FetchRelatedGeoAreaResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `geographic/getrelatedgeoareasbyid?geoAreaId=${geoAreaId}`,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Geographic API
 * @see fetchGeoArea
 * */
type FetchRelatedGeoCountriesParams = {
    deployment: string;
};

/**
 * GET request to geographic api service that sends in a geoAreaId and returns list of related geoAreas
 * @function fetchRelatedGeoAreaCountries
 * @category Geographic API
 * @param {FetchRelatedGeoAreaParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/geographic/getgeoareacountries
 * @see https://github.com/LIVEauctioneers/geographic/blob/main/internal/services/GeoAreaService.go
 * */
export const fetchRelatedGeoAreaCountries = ({ deployment }: FetchRelatedGeoCountriesParams) =>
    new Promise<FetchRelatedGeoAreaResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'geographic/getgeoareacountries',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Geographic API
 * @see fetchAllGeoAreas
 * */
type FetchAllGeoAreasResponse = {
    data: GeoArea[];
    error: boolean;
};

/**
 * GET request to geographic api service that returns all available geoAreas
 * @function fetchAllGeoAreas
 * @category Geographic API
 * @param deployment environment to pull data from
 * */
export const fetchAllGeoAreas = (deployment: string) =>
    new Promise<FetchAllGeoAreasResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'geographic/getgeoareas',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Geographic API
 * @see fetchRelatedGeoAreaByCityStateCountry
 */
type FetchRelatedGeoAreaByCityStateCountryParams = {
    catalogId: number;
    deployment: string;
};

/**
 * @category Geographic API
 * @see fetchRelatedGeoAreaByCityStateCountry
 * */
type FetchRelatedGeoAreaByCityStateCountryResponse = {
    data: RelatedGeoArea;
    error: boolean;
};

/**
 * GET request to geographic api service that returns geo area based on the provided city, state, and country
 * @function fetchRelatedGeoAreaByCityStateCountry
 * @category Geographic API
 * @param {FetchRelatedGeoAreaByCityStateCountryParams}
 * @param {string} params.city - if unpopulated, it will be treated as an empty string in the API request
 * @param {string} params.country - should be a two-letter country code (e.g. 'US' for United States of America, 'CA' for Canada, etc.)
 * @param {string} params.deployment - environment to pull data from
 * @param {string} params.state - if unpopulated, it will be treated as an empty string in the API request. The API will only use the state field for US-based geo areas. It should be a two-letter state code (e.g. 'UT' for Utah, 'NY' for New York, etc.)
 */
export const fetchRelatedGeoAreaByCityStateCountry = ({
    catalogId,
    deployment,
}: FetchRelatedGeoAreaByCityStateCountryParams) =>
    new Promise<FetchRelatedGeoAreaByCityStateCountryResponse>((resolve, reject) => {
        // the url needs "" to recognize empty strings in the url
        const request = makeGet({
            apiPath: 'geographic/getrelatedgeoareabycitystatecountry',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: { catalog_id: catalogId },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
