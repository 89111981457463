import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';
import { Notification } from '@/types/Notification';

/**
 * @category Telegram API
 * @see {fetchNotificationsParams}
 */
type FetchNotificationsParams = {
    authToken: string;
    deployment: string;
};

/**
 * @category Telegram API
 * @see {fetchNotificationsParams}
 */
type FetchNotificationsResponse = {
    error: boolean;
    payload: Notification[];
};

/**
 * fetches current notifications via Get request to Telegram API
 * @function fetchNotifications
 * @category Telegram API
 * @param {FetchNotificationsParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/getNotifications
 * @see https://github.com/LIVEauctioneers/telegram/blob/master/internal/handlers/notificationsHandler.go
 */
export const fetchNotifications = ({ authToken, deployment }: FetchNotificationsParams) =>
    new Promise<FetchNotificationsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getNotifications',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Telegram API
 * @see {postDisableWebPushSubscription}
 */
type PostDisableWebPushSubscriptionParams = {
    authToken: string;
    deployment: string;
};

/**
 * @category Telegram API
 * @see {postDisableWebPushSubscription}
 */
type PostDisableWebPushSubscriptionResponse = {
    error: boolean;
    payload: string;
};

/**
 * sends disable web push subscription request via Post to Telegram API
 * @function postDisableWebPushSubscription
 * @category Telegram API
 * @param {PostDisableWebPushSubscriptionParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/disableWebPushSubscription
 * @see https://github.com/LIVEauctioneers/telegram/blob/master/internal/handlers/notificationPreferencesHandler.go
 */
export const postDisableWebPushSubscription = ({ authToken, deployment }: PostDisableWebPushSubscriptionParams) =>
    new Promise<PostDisableWebPushSubscriptionResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'disableWebPushSubscription',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send();
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Telegram API
 * @see {postMarkDeleted}
 */
type PostMarkDeletedParams = {
    authToken: string;
    deployment: string;
    notificationIds: any;
};

/**
 * @category Telegram API
 * @see {postMarkDeleted}
 */
type PostMarkDeletedResponse = {
    error: boolean;
    payload: string;
};

/**
 * updates a notification as deleted via Post to Telegram API
 * @function postMarkDeleted
 * @category Telegram API
 * @param {PostMarkDeletedParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/markNotificationsDeleted
 * @see https://github.com/LIVEauctioneers/telegram/blob/master/internal/handlers/notificationsHandler.go
 */
export const postMarkDeleted = ({ authToken, deployment, notificationIds }: PostMarkDeletedParams) =>
    new Promise<PostMarkDeletedResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'markNotificationsDeleted',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send({ ids: notificationIds });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Telegram API
 * @see {postMarkRead}
 */
type PostMarkReadParams = {
    authToken: string;
    deployment: string;
    notificationIds: any;
};

/**
 * @category Telegram API
 * @see {postMarkRead}
 */
type PostMarkReadResponse = {
    error: boolean;
    payload: string;
};

/**
 * updates a notification as read via Post to Telegram API
 * @function postMarkRead
 * @category Telegram API
 * @param {PostMarkReadParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/markNotificationsRead
 * @see https://github.com/LIVEauctioneers/telegram/blob/master/internal/handlers/notificationsHandler.go
 */
export const postMarkRead = ({ authToken, deployment, notificationIds }: PostMarkReadParams) =>
    new Promise<PostMarkReadResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'markNotificationsRead',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send({ ids: notificationIds });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Telegram API
 * @see postUpdateWebPushSubscription
 */
type PostUpdateWebPushSubscriptionParams = {
    authToken: string;
    deployment: string;
    subscription: any;
};

/**
 * @category Telegram API
 * @see postUpdateWebPushSubscription
 */
type PostUpdateWebPushSubscriptionResponse = {
    error: boolean;
    payload: string;
};

/**
 * updates a web push subscription request via Post to Telegram API
 * @function postUpdateWebPushSubscription
 * @category Telegram API
 * @param {PostUpdateWebPushSubscriptionParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/updateWebPushSubscription
 * @see https://github.com/LIVEauctioneers/telegram/blob/master/internal/handlers/notificationPreferencesHandler.go
 */
export const postUpdateWebPushSubscription = ({
    authToken,
    deployment,
    subscription,
}: PostUpdateWebPushSubscriptionParams) =>
    new Promise<PostUpdateWebPushSubscriptionResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updateWebPushSubscription',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send(subscription);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
