import { ApiHosts, handleResponseOld, makeGet } from './helpers';

type fetchSuggestedCategoriesBySearchTermParams = {
    categoryId: string;
    deployment: string;
};

type fetchSuggestedCategoriesBySearchTermResponse = {
    error: boolean;
    payload: [
        {
            defaultImageUrl: string;
            filterFacetId: number;
            imageUrl: string;
            position: number;
            title: string;
            url: string;
        },
    ];
};

/**
 * Fetches suggested categories our people have manually added, based on current category
 * @function fetchSuggestedCategoriesByCategoryID
 * @category CATEGORY API
 * @param {fetchSuggestedCategoriesBySearchTermParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/getsuggestedcategoriestiles?categoryId={}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/xi40gl/methods/GET
 */
export const fetchSuggestedCategoriesByCategoryID = ({
    categoryId,
    deployment,
}: fetchSuggestedCategoriesBySearchTermParams) =>
    new Promise<fetchSuggestedCategoriesBySearchTermResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getsuggestedcategoriestiles',
            deployment,
            path: ApiHosts.Category,
        });
        request.query({ categoryId });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
