import { ApiHosts, handleResponseOld, makeDelete, makeGet } from './helpers';

/**
 * @category Taxes API
 * @see deleteCertificate
 */
type DeleteCertificateTypeParams = {
    authToken: string;
    bidderId: number;
    certificateId: number;
    deployment: string;
};
/**
 * @category Taxes API
 * @see deleteCertificate
 */
type DeleteCertificateResponse = {
    error: boolean;
    payload: string;
};
/**
 * Deletes a tax certificate from via the Taxes API
 * @function deleteCertificate
 * @category Taxes API
 * @param {DeleteCertificateTypeParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/exemption/bidder/${bidderId}/certificates/${certificateId}
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
export const deleteCertificate = ({ authToken, bidderId, certificateId, deployment }: DeleteCertificateTypeParams) =>
    new Promise<DeleteCertificateResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `exemption/bidder/${bidderId}/certificates/${certificateId}`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Taxes API
 * @see getCertCaptureToken
 */
type GetCertCaptureTypeParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};
/**
 * @category Taxes API
 * @see getCertCaptureToken
 */
type GetCertCaptureTokenResponse = {
    error: boolean;
    payload: string;
};
/**
 * Gets a Tax Certificate Capture token for given bidder
 * @function getCertCaptureToken
 * @category Taxes API
 * @param {GetCertCatpureTypeParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/exemption/bidder/${bidderId}/token
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
export const getCertCaptureToken = ({ authToken, bidderId, deployment }: GetCertCaptureTypeParams) =>
    new Promise<GetCertCaptureTokenResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `exemption/bidder/${bidderId}/token`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Taxes API
 * @see getCertificateDownload
 */
type GetCertificateDownloadTypeParams = {
    authToken: string;
    bidderId: number;
    certificateId: number;
    deployment: string;
};
/**
 * @category Taxes API
 * @see getCertificateDownload
 */
type GetCertificateDownloadResponse = {
    error: boolean;
    payload: string;
};
/**
 * Gets Tax Exemption Certificate PDF download info
 * @function getCertificateDownload
 * @category Taxes API
 * @param {GetCertificateDownloadTypeParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/exemption/bidder/${bidderId}/certificates/${certificateId}/pdf
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
export const getCertificateDownload = ({
    authToken,
    bidderId,
    certificateId,
    deployment,
}: GetCertificateDownloadTypeParams) =>
    new Promise<GetCertificateDownloadResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `exemption/bidder/${bidderId}/certificates/${certificateId}/pdf`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Taxes API
 * @see getCertificates
 */
type GetCertificatesTypeParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * @category Taxes API
 * @see getCertificates
 */
type GetCertificatesResponse = {
    error: boolean;
    payload: {
        expiration: string;
        id: number;
        signed: string;
        state: string;
        status: string;
        valid: boolean;
    }[];
};
/**
 * Get available Tax certificates for bidder
 * @function getCertificates
 * @category Taxes API
 * @param {GetCertificatesTypesParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/exemption/bidder/${bidderId}/certificates
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
export const getCertificates = ({ authToken, bidderId, deployment }: GetCertificatesTypeParams) =>
    new Promise<GetCertificatesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `exemption/bidder/${bidderId}/certificates`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
