import { ApiHosts, handleResponseOld, makeGet } from './helpers';

type fetchSubcategoriesBySearchTermParams = {
    categoryId: string;
    deployment: string;
};

type fetchSubcategoriesBySearchTermResponse = {
    error: boolean;
    payload: [
        {
            imageURL: string;
            lotCount: number;
            title: string;
        },
    ];
};

/**
 * Fetches related searched from discover page title table, based on current item (search term)
 * @function fetchSubcategoriesByCategoryID
 * @category CATEGORY API
 * @param {fetchSubcategoriesBySearchTermParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/subcategory?categoryId={}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/xi40gl/methods/GET
 */
export const fetchSubcategoriesByCategoryID = ({ categoryId, deployment }: fetchSubcategoriesBySearchTermParams) =>
    new Promise<fetchSubcategoriesBySearchTermResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'subcategory',
            deployment,
            path: ApiHosts.Category,
        });
        const carousel = 'true';
        request.query({ carousel, categoryId });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
