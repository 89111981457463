import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Personalization API
 * @category Recommendation API
 * @see fetchRecommendedItem
 */
type FetchRecommendedItemParams = {
    amazon: boolean;
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * @category Personalization API
 * @category Recommendation API
 * @see fetchRecommendedItem
 */
type FetchRecommendedItemResponse = {
    data: number[];
};

/**
 * fetches recommended item from Recommendation or Personalization APIs
 * @function fetchRecommendedItem
 * @category Personalization API
 * @param {FetchRecommendedItemParams}
 * @see https://api.liveauctioneers.com/recommendation/getrecommendations/DEPLOYMENT/${bidderId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/knv1rmrjt8/resources/02ughn/methods/GET
 * @see https://github.com/LIVEauctioneers/recommendation-api/blob/master/functions/GetRecommendations/main.go
 * @see https://api-DEPLOYMENT.liveauctioneers.com/personalize/recommend/${bidderId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/7z3p7o1q10/resources/50zx03/methods/GET
 */
export default {
    fetchRecommendedItem: ({ amazon, authToken, bidderId, deployment }: FetchRecommendedItemParams) =>
        new Promise<FetchRecommendedItemResponse>((resolve, reject) => {
            const path = amazon ? ApiHosts.Personalize : ApiHosts.ItemApi;
            const apiPath = amazon ? `${bidderId}` : 'recommendation/getrecommendations';
            const request = makeGet({
                apiPath,
                authToken,
                deployment,
                path,
                useCacheKey: false,
            });
            request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
        }),
};
