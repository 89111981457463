import { ApiHosts, handleResponseOld, makeGet } from './helpers';

type fetchCombinationCategoryByConcatedIDsParams = {
    concatedIDs: string;
    deployment: string;
};

type fetchCombinationCategoryByConcatedIDsResponse = {
    error: boolean;
    payload: {};
};

/**
 * Fetches catalog ids from algolia, based on current category name (search term)
 * @function fetchCombinationCategoryByConcatedIDs
 * @category CATEGORY API
 * @param {fetchCombinationCategoryByConcatedIDsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/jvpa6u/methods/GET
 */
export const fetchCombinationCategoryByConcatedIDs = ({
    concatedIDs,
    deployment,
}: fetchCombinationCategoryByConcatedIDsParams) =>
    new Promise<fetchCombinationCategoryByConcatedIDsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationcategorybyconcatids',
            deployment,
            path: ApiHosts.Category,
        });
        request.query({ concatedIds: concatedIDs });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });

type fetchCombinationSuggestedCategoriesByConcatedIDsParams = {
    concatedIDs: string;
    deployment: string;
};

type fetchCombinationSuggestedCategoriesByConcatedIDsResponse = {
    error: boolean;
    payload: [];
};

/**
 * Fetches catalog ids from algolia, based on current category name (search term)
 * @function fetchCombinationSuggestedCategoriesByConcatedIDs
 * @category CATEGORY API
 * @param {fetchCombinationSuggestedCategoriesByConcatedIDsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/uo83iz/methods/GET
 */
export const fetchCombinationSuggestedCategoriesByConcatedIDs = ({
    concatedIDs,
    deployment,
}: fetchCombinationSuggestedCategoriesByConcatedIDsParams) =>
    new Promise<fetchCombinationSuggestedCategoriesByConcatedIDsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationsuggestedcategoriestiles',
            deployment,
            path: ApiHosts.Category,
        });
        request.query({ concatedFacetIDs: concatedIDs });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });

type fetchCombinationRelatedSearchesByConcatedIDsParams = {
    concatedIDs: string;
    deployment: string;
};

type fetchCombinationRelatedSearchesByConcatedIDsResponse = {
    error: boolean;
    payload: [];
};

/**
 * Fetches catalog ids from algolia, based on current category name (search term)
 * @function fetchCombinationRelatedSearchesByConcatedIDs
 * @category CATEGORY API
 * @param {fetchCombinationRelatedSearchesByConcatedIDsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/uo83iz/methods/GET
 */
export const fetchCombinationRelatedSearchesByConcatedIDs = ({
    concatedIDs,
    deployment,
}: fetchCombinationRelatedSearchesByConcatedIDsParams) =>
    new Promise<fetchCombinationRelatedSearchesByConcatedIDsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcombinationrelatedsearchestiles',
            deployment,
            path: ApiHosts.Category,
        });
        request.query({ concatedFacetIDs: concatedIDs });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
