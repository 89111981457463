import { ApiHosts, handleResponseOld, makeGet } from './helpers';

type fetchCategoryCatalogIdsBySearchTermParams = {
    deployment: string;
    searchTerm: string;
};

type fetchCategoryCatalogIdsBySearchTermResponse = {
    error: boolean;
    payload: [];
};

/**
 * Fetches catalog ids from algolia, based on current category name (search term)
 * @function fetchCategoryCatalogIdsByCategoryID
 * @category SEARCH API
 * @param {fetchCategoryCatalogIdsBySearchTermParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/catalogsbysearchterm?searchTerm={}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/xi40gl/methods/GET
 */
export const fetchCatalogIdsBySearchTerm = ({ deployment, searchTerm }: fetchCategoryCatalogIdsBySearchTermParams) =>
    new Promise<fetchCategoryCatalogIdsBySearchTermResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'catalogsbysearchterm',
            deployment,
            path: ApiHosts.Search,
        });
        request.query({ searchTerm });
        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
