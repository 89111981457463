import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Stats API
 * @see fetchRecentItems
 */
type FetchRecentItemsParams = {
    authToken: string;
    deployment: string;
};

type FetchRecentItemsResponse = {
    data: {
        recentItems: [
            {
                itemId: number;
                timestamp: number;
            },
        ];
    };
    message: string;
    success: boolean;
};

/**
 * Fetches Recent items from the Stats API
 * @function fetchRecentItems
 * @category Stats API
 * @param {FetchRecentItemsParams}
 * @returns {FetchRecentItemsResponse}
 * @see https://crispy-pancake-DEPLOYMENT.liveauctioneers.com/recent
 * @see https://github.com/LIVEauctioneers/crispy-pancake/blob/master/src/recent/routes/get-recent.ts
 */
const fetchRecentItems = ({ authToken, deployment }: FetchRecentItemsParams) =>
    new Promise<FetchRecentItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'recent',
            authToken,
            deployment,
            path: ApiHosts.Stats,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchRecentItems,
};
