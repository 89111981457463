import { handleResponseOld } from './helpers';
import superagent from 'superagent';

/**
 * calls express-healthcheck to verify bidder-react server status
 * @function fetchHealthCheckData
 * @category Bidder React API
 * @see https://github.com/lennym/express-healthcheck
 * @see https://github.com/LIVEauctioneers/bidder-react/blob/cfa674947011ddb78f6ded7a7419deb9431f7686/src/server/server.js#L196
 */
const fetchHealthCheckData = () =>
    new Promise<any>((resolve, reject) => {
        const request = superagent.get('/healthcheck');
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchHealthCheckData,
};
