import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Stream API
 * @see fetchStreamId
 */
type FetchStreamIdParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
};
/**
 * gets video streaming id for given catalogId via Get to Stream API
 * @function fetchStreamId
 * @category Stream API
 * @param {FetchStreamIdParams}
 * @see https://api.liveauctioneers.com/stream/status/${catalogId}/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/l8twqmfbxg/resources/9sswf0/methods/GET
 * @see https://github.com/LIVEauctioneers/floodgate/tree/master/functions/Stream
 */
const fetchStreamId = ({ authToken, catalogId, deployment }: FetchStreamIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `status/${catalogId}`,
            authToken,
            deployment,
            path: ApiHosts.StreamManager,
        });

        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

export default {
    fetchStreamId,
};
