import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category Mainhost API
 * @see postItemRemovalResponse
 */
type PostItemRemovalResponseParams = {
    authToken: string;
    deployment: string;
    lotId: string;
    response: string;
    timestamp: string;
    verificationCode: string;
};
/**
 * @category Mainhost API
 * @see postItemRemovalResponse
 */
type PostItemRemovalResponseResponse = {
    message: string;
    success: boolean;
};

/**
 * postItemRemovalResponse - updates user password
 * @category Mainhost API
 * @function postItemRemovalResponse
 * @param {PostItemRemovalResponseParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/api/eoa_removal_response
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/libs/MOBILE_API.php#L720
 */
export const postItemRemovalResponse = ({
    authToken,
    deployment,
    lotId,
    response,
    timestamp,
    verificationCode,
}: PostItemRemovalResponseParams) =>
    new Promise<PostItemRemovalResponseResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'api/eoa_removal_response',
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
            queryParams: { client_version: '4.3' },
        });
        request.field('l', lotId || '');
        request.field('r', response || '');
        request.field('ts', timestamp || '');
        request.field('v', verificationCode || '');
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
