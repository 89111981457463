import isEmpty from 'lodash/isEmpty';
import superagent from 'superagent';

const cachekey = '20170802';

/*
{
    onBuildRequest: null,
    onSendRequest: null,
    onHandleResponse: null,
}
*/
let perfLoggerRequestHooks: any = null;

type HandleResponseParams = {
    err?: any;
    manuallyHandledErrorCodes?: number[];
    reject: Function;
    request?: superagent.SuperAgentRequest;
    resolve: Function;
    response: any;
    success404?: boolean;
    transform?: Function;
};

export const handleResponseWithNon200Errors = ({
    err,
    manuallyHandledErrorCodes,
    reject,
    request,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponseParams) => {
    /* eslint-disable-next-line */
    const rejectAndCaptureError = (errorToReturn: any, originalError = null) => {
        // We can capture the error here if we want to send the data to some future logging service
        // captureError(originalError || errorToReturn);
        return reject(errorToReturn);
    };

    const handleRequestError = () => {
        let errorMessage = '';
        try {
            errorMessage =
                'Here is the error with the request: ' +
                JSON.stringify(err) +
                ' when making requests to: ' +
                request?.url;
        } catch (e) {
            errorMessage = 'Failed to stringify the error from the request.';
        }
        return rejectAndCaptureError(errorMessage);
    };

    // If there is a server level error, reject the promise
    if (err) {
        if (manuallyHandledErrorCodes?.includes(err.status)) {
            return reject(err.status);
        }
        if (success404 && err.status === 404) {
            return resolve(response.body || {});
        }
        // If there is not a  response, we can handle the error now. (CORS errors look like this)
        if (isEmpty(response)) {
            return handleRequestError();
        }
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    // Go ResponseEnvelope
    if ('error' in body && body.error) {
        // Special case with clerk commands where "currentState" is in meta on failure
        if (body?.meta?.currentState) {
            return rejectAndCaptureError(body, body.payload);
        }
        return rejectAndCaptureError(body.payload);
    }

    // If the success and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'message' in body && !body.success) {
        return rejectAndCaptureError(body.message);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        if ('payload' in body) {
            if ('error' in body.payload) {
                // Check if the error is from the create account endpoint for the verify email screen
                if (body.payload.error === 'User Already Exists') {
                    return resolve(body);
                }
                return rejectAndCaptureError(body.payload.error);
            }
            return rejectAndCaptureError(body.payload);
        }
        return rejectAndCaptureError(body);
    }

    //if the response has an errorMessage it means it's broken
    if (Boolean(body.errorMessage)) {
        return rejectAndCaptureError(body.errorMessage);
    }

    if (err) {
        // If we have gotten this far and there was an err object, handle it
        return handleRequestError();
    }

    // else
    return resolve(body);
};

export const handleResponseOld = ({
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponseParams) => {
    // If there is a server level error, reject the promise
    if (err) {
        if (success404 && err.status === 404) {
            // dante gets to here and needs to be transformed.  look at how I do this in hardboiled - blake 2019-09-26
            return resolve(response.body || {});
        } else if (response?.body?.error && response?.body?.payload === 'User Already Exists') {
            // the server correctly responds with a 500 when the user exists
            return reject(response.body.payload);
        }
        return reject(err);
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    // I saw a case where transform returned an undefined
    if (!body) {
        return reject('Unexpected error transforming response');
    }

    // If the success and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'message' in body && !body.success) {
        if (body?.error === 'Failed to authenticate a') {
            return reject(body.error);
        }
        return reject(body.message);
    }

    if ('success' in body && 'payload' in body && !body.success) {
        return reject(body.payload);
    }

    // Go ResponseEnvelope
    if ('error' in body && body.error) {
        if ('payload' in body) {
            // Proper go response is to have the payload be an error string when the error property is true
            if (typeof body.payload === 'string') {
                return reject(body.payload);
            }
            if (typeof body.payload === 'object') {
                // The createuser endpoint in flynn returns a non standard object when a bidder already exists
                if (body.payload.error === 'User Already Exists') {
                    return reject(body.payload.error);
                }
            }
        }
        // If the response is built correctly, the payload would have been a string and already rejected
        // but to keep the code backward compatible, we will reject with the payload anyway
        return reject(body.payload);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        if ('payload' in body) {
            if ('error' in body.payload) {
                return reject(body.payload.error);
            }
            return reject(body.payload);
        }
        return reject(body);
    }

    //if the response has an errorMessage it means it's broken
    if (Boolean(body.errorMessage)) {
        return reject(body.errorMessage);
    }
    // else
    return resolve(body);
};

export const handleResponseUseItemApiError = ({
    err,
    reject,
    resolve,
    response = {},
    success404,
    transform,
}: HandleResponseParams) => {
    // If there is a server level error, reject the promise
    if (err) {
        if (success404 && err.status === 404) {
            resolve(response.body || {});
        }
        return reject(err);
    }

    let body = response.body || {};
    if (transform) {
        body = transform(body);
    }

    // If the successa and message properties are set in body (item-api response)
    // but success is falsy, reject the promise
    if ('success' in body && 'error' in body && !body.success) {
        return reject(body.error);
    }

    // If the success property is set in body but is falsy, reject the promise
    // Might be some sort of lambda (Flynn)
    if ('success' in body && !body.success) {
        return reject(body);
    }

    // else
    return resolve(body);
};

const addCacheKey = (request: superagent.SuperAgentRequest) => {
    request.query({ c: cachekey });
    return request;
};

/* eslint-disable typescript-sort-keys/string-enum */
export enum ApiHosts {
    // remove -DEPLOYMENT for production release
    MarketPlace = 'https://api-DEPLOYMENT.liveauctioneers.com/marketplace/APIPATH',
    Payment = 'https://api-DEPLOYMENT.liveauctioneers.com/payment/APIPATH',

    // -DEPLOYMENT becomes -barako/-stage/-preprod/-prod
    Approval = 'https://approval-DEPLOYMENT.liveauctioneers.com/approval/APIPATH',
    Announcements = 'https://api-DEPLOYMENT.liveauctioneers.com/announcement-api/APIPATH',
    AuctionEngine = 'https://bidder-react-DEPLOYMENT.liveauctioneers.com/APIPATH',
    AuctionService = 'https://auction-service-DEPLOYMENT.liveauctioneers.com/APIPATH',
    Authentication = 'https://authentic-DEPLOYMENT.liveauctioneers.com/APIPATH',
    Billing = 'https://billing-DEPLOYMENT.liveauctioneers.com/APIPATH',
    CatalogManagement = 'https://catalog-management-DEPLOYMENT.liveauctioneers.com/catalog/APIPATH',
    Category = 'https://category-api-DEPLOYMENT.liveauctioneers.com/category-api/APIPATH',
    Collection = 'https://api.liveauctioneers.com/collections/APIPATH/DEPLOYMENT',
    Crows = 'https://saveditem-crows-DEPLOYMENT.liveauctioneers.com/services/saveditem-crows/APIPATH',
    FlynnUser = 'https://flynn-DEPLOYMENT.liveauctioneers.com/user/APIPATH',
    FpApi = 'https://api.liveauctioneers.com/fp-DEPLOYMENT/APIPATH',
    ItemApi = 'https://item-api-DEPLOYMENT.liveauctioneers.com/APIPATH',
    ItemModel = 'https://catalog-bidder-content-DEPLOYMENT.liveauctioneers.com/content/APIPATH',
    LegacySavedSearch = 'https://api.liveauctioneers.com/savedsearch/APIPATH/DEPLOYMENT',
    Mainhost = 'https://mainhost-DEPLOYMENT.liveauctioneers.com/APIPATH',
    MarketingReports = 'https://api-DEPLOYMENT.liveauctioneers.com/marketing-reports/APIPATH',
    Messaging = 'https://api-DEPLOYMENT.liveauctioneers.com/messaging/APIPATH',
    MessagingAttachments = 'https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/APIPATH',
    Notifications = 'https://telegram-DEPLOYMENT.liveauctioneers.com/APIPATH',
    Personalization = 'https://api-DEPLOYMENT.liveauctioneers.com/personalization/APIPATH',
    Personalize = 'https://api-DEPLOYMENT.liveauctioneers.com/personalize/recommend/APIPATH',
    PubNub = 'https://ps.pndsn.com/APIPATH',
    Redstripe = 'https://redstripe-DEPLOYMENT.liveauctioneers.com/payment-api/APIPATH',
    Review = 'https://review-service-DEPLOYMENT.liveauctioneers.com/review/APIPATH',
    SavedItems = 'https://catalog-bidder-content-DEPLOYMENT.liveauctioneers.com/content/APIPATH',
    SavedSearch = 'https://api-DEPLOYMENT.liveauctioneers.com/savedsearch/APIPATH',
    Search = 'https://search-party-DEPLOYMENT.liveauctioneers.com/search/APIPATH',
    SemLedger = 'https://sem-ENV.liveauctioneers.com',
    Shipping = 'https://shipping-api-DEPLOYMENT.liveauctioneers.com/shipping/APIPATH',
    Stats = 'https://crispy-pancake-DEPLOYMENT.liveauctioneers.com/APIPATH',
    StreamManager = 'https://api.liveauctioneers.com/stream/APIPATH/DEPLOYMENT',
    Taxes = 'https://billing-DEPLOYMENT.liveauctioneers.com/taxes/APIPATH',
    ThirdPartyAuth = 'https://third-party-auth-DEPLOYMENT.liveauctioneers.com/APIPATH',
    Title = 'https://api-DEPLOYMENT.liveauctioneers.com/title/APIPATH',
    Validation = 'https://validation-DEPLOYMENT.liveauctioneers.com/APIPATH',
}
/* eslint-enable typescript-sort-keys/string-enum */

const dropDeploymentReplacementForProductionHosts = [ApiHosts.MarketPlace];

export const buildUrl = (hostPath: ApiHosts, deployment: string = '', apiPath: string = '') => {
    // convert the incoming path to a string so that we can edit it
    let updatedPath: string = hostPath;
    let url: string = '';

    if (deployment === 'prod' && dropDeploymentReplacementForProductionHosts.includes(hostPath)) {
        if (updatedPath.indexOf('-DEPLOYMENT') !== -1) {
            url = updatedPath.replace('-DEPLOYMENT', '');
        }
    } else {
        url = updatedPath.replace('DEPLOYMENT', deployment);
    }

    // Upgrade auction-service api call prefix to 'www' in production
    if (updatedPath === ApiHosts.AuctionEngine && deployment === 'prod') {
        const subdomain = new URL(url).hostname.split('.')[0];
        url = url.replace(subdomain, 'www');
    }

    // Upgrade item-model-api/saved-items-api api call prefix to 'www' in production
    if (
        (updatedPath === ApiHosts.ItemModel || updatedPath === ApiHosts.SavedItems) &&
        deployment === 'prod' &&
        process.env.NODE_ENV !== 'development'
    ) {
        url = updatedPath.replace('catalog-bidder-content-DEPLOYMENT', 'www');
    }

    updatedPath = updatedPath.replace(updatedPath, url);
    updatedPath = updatedPath.replace('APIPATH', apiPath);

    return updatedPath;
};

type MakeRequest = {
    apiPath?: string;
    authToken?: string;
    deployment?: string;
    forceCredentials?: boolean;
    ipAddress?: string;
    path: ApiHosts;
    queryParams?: any;
    useCacheKey?: boolean;
};

type MakeRequestPrivate = MakeRequest & {
    requestFunc:
        | typeof superagent.delete
        | typeof superagent.get
        | typeof superagent.post
        | typeof superagent.patch
        | typeof superagent.put;
};

const buildRequest = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials = false,
    ipAddress,
    path,
    queryParams,
    requestFunc,
    useCacheKey = true,
}: MakeRequestPrivate) => {
    const url = buildUrl(path, deployment, apiPath);
    const request = requestFunc(url);

    if (
        (path && (path === ApiHosts.ItemApi || path.includes('s_msg') || path.includes('eoa_removal_response'))) ||
        forceCredentials
    ) {
        request.withCredentials();
    }

    // set our own useragent for the ssr
    if (typeof window === 'undefined') {
        request.set({ 'User-Agent': 'la-superagent' });
    }

    if (useCacheKey) {
        addCacheKey(request);
    }

    if (queryParams) {
        request.query(queryParams);
    }

    if (authToken) {
        request.set('Authorization', `Bearer ${authToken}`);
    }

    if (ipAddress && typeof window === 'undefined') {
        request.set('X-Forwarded-For', ipAddress);
    }

    if (perfLoggerRequestHooks) {
        const { onBuildRequest, onHandleResponse, onSendRequest } = perfLoggerRequestHooks;
        onBuildRequest(request);

        const _end = request.end;
        request.end = (cb) => {
            onSendRequest(request, { url });
            return _end.bind(request)((error, response) => {
                cb?.(error, response);
                onHandleResponse(request, response, error);
            });
        };
    }

    return request;
};

export const makeDelete = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    ipAddress,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        ipAddress,
        path,
        queryParams,
        requestFunc: superagent.delete,
        useCacheKey,
    });

export const makeGet = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    ipAddress,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        ipAddress,
        path,
        queryParams,
        requestFunc: superagent.get,
        useCacheKey,
    });

export const makePatch = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    ipAddress,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        ipAddress,
        path,
        queryParams,
        requestFunc: superagent.patch,
        useCacheKey,
    });

export const makePost = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    ipAddress,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        ipAddress,
        path,
        queryParams,
        requestFunc: superagent.post,
        useCacheKey,
    });

export const makePut = ({
    apiPath,
    authToken,
    deployment,
    forceCredentials,
    ipAddress,
    path,
    queryParams,
    useCacheKey = true,
}: MakeRequest) =>
    buildRequest({
        apiPath,
        authToken,
        deployment,
        forceCredentials,
        ipAddress,
        path,
        queryParams,
        requestFunc: superagent.put,
        useCacheKey,
    });

type SetGlobalRequestHooksParams = {
    onBuildRequest: Function;
    onHandleResponse: Function;
    onSendRequest: Function;
};

export const setGlobalPerfLoggerRequestHooks = ({
    onBuildRequest,
    onHandleResponse,
    onSendRequest,
}: SetGlobalRequestHooksParams) => {
    perfLoggerRequestHooks = {
        onBuildRequest,
        onHandleResponse,
        onSendRequest,
    };
};
