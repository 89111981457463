import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Item API
 * @see fetchFeaturedCatalogs
 */
type FetchFeaturedCatalogsParams = {
    deployment: string;
};

/**
 * @category Item API
 * @see fetchFeaturedCatalogs
 */
type FetchFeaturedCatalogsResponse = {
    data: number[];
    message: string;
    success: boolean;
};
/**
 * Fetches cover lots via post to Item API Service for given catalogIds
 * @function fetchFeaturedCatalogs
 * @category Item API
 * @param {FetchFeaturedCatalogsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/featured/catlogs
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-featured-catalogs.ts
 */
const fetchFeaturedCatalogs = ({ deployment }: FetchFeaturedCatalogsParams) =>
    new Promise<FetchFeaturedCatalogsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'featured/catalogs',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchFeaturedCatalogs,
};
