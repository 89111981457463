import { ApiHosts, handleResponseOld, makeGet } from './helpers';
import { Page } from '@/types/Page';

/**
 * @category Item API
 * @see fetchAboutData
 */
type FetchAboutDataParams = {
    deployment: string;
};

/**
 * @category Item API
 * @see fetchAboutData
 */
type FetchAboutDataResponse = {
    data: Page;
    message: string;
    success: boolean;
};

/**
 * fetches About data for a given page via Get request to Item API
 * @function fetchAboutData
 * @category Item API
 * @param {FetchAboutDataParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spapage/about
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-about-page.ts
 */
export const fetchAboutData = ({ deployment }: FetchAboutDataParams) =>
    new Promise<FetchAboutDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spapage/about',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
