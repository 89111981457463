import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Search API
 * @see fetchSimilarItems
 */
type FetchSimilarItemsParams = {
    authToken: string;
    deployment: string;
    ipAddress: string;
    isBot: boolean;
    page?: number;
    searchExclusions: number[];
    similarItemInfo: any;
};
/**
 * fetches similar items via Get to Search API
 * @function fetchSimilarItems
 * @category Search API
 * @param {FetchSimilarItemsParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/similaritems-
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/qa8ucb/methods/GET
 * @see https://github.com/LIVEauctioneers/search-party/blob/master/functions/SimilarItems/main.go
 */
const fetchSimilarItems = ({
    authToken,
    deployment,
    ipAddress,
    page,
    searchExclusions = [],
    similarItemInfo,
}: FetchSimilarItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const params = {
            options: {
                auctionHouse: [{ exclude: searchExclusions, include: [] }],
                status: ['upcoming', 'live', 'online'],
            },
            page: page,
            pageSize: 12,
            searchTerm: similarItemInfo.title,
        };

        const request = makeGet({
            apiPath: 'v4/similaritems',
            authToken,
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });

        request.query({
            houseId: similarItemInfo.sellerId,
            ids: JSON.stringify(similarItemInfo.categoryIds),
            itemId: similarItemInfo.itemId,
            parameters: JSON.stringify(params),
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
/**
 * fetches SOLD similar items via Get to Search API
 * @function fetchSimilarSoldItems
 * @category Search API
 * @param {FetchSimilarItemsParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/similarsolditems
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/l1y02d/methods/GET
 * @see https://github.com/LIVEauctioneers/search-party/blob/master/functions/SimilarSoldItems/main.go
 */
const fetchSimilarSoldItems = ({
    authToken,
    deployment,
    ipAddress,
    isBot,
    similarItemInfo,
    /* eslint-disable-next-line */
    searchExclusions = [],
}: FetchSimilarItemsParams) =>
    new Promise<any>((resolve, reject) => {
        const { categoryIds = [], itemId: lotId, pageSize = 16, sellerId: houseId, title } = similarItemInfo;

        // houseId - houseId for the house that listed the item (optional)
        // ids - comma separated list of categoryIds for the item (optional)
        // lotId - the current item we are looking for similar items for
        // page - page to return (optional) defaults to 1, probably never needed
        // pageSize - max number of items to return (optional) defaults to 16
        // title - url encoded title of the current item (required)

        const request = makeGet({
            apiPath: 'v4/similarsolditems',
            authToken,
            deployment,
            ipAddress,
            path: ApiHosts.Search,
        });
        request.query({
            houseId,
            ids: categoryIds.join(','),
            isBot,
            lotId,
            // page - not currently passing this because we are only using the first few
            pageSize,
            title: encodeURIComponent(title),
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchSimilarItems,
    fetchSimilarSoldItems,
};
