import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';
import { BuyNow } from '@/types/BuyNow';
import { ItemShipping } from '@/types/ItemShipping';

/**
 * @category Item API
 * @see fetchBuyNowByIds
 */
type FetchBuyNowsByIdsParams = {
    deployment: string;
    itemIds: number[];
};
/**
 * @category Item API
 * @see fetchBuyNowByIds
 */
type FetchBuyNowsByIdsResponse = {
    data: BuyNow[];
    message: string;
    success: boolean;
};
/**
 * Fetches item buy now data for given item ids via Fetch request to Item API service
 * @function fetchBuyNowsByIds
 * @category Item API
 * @param {FetchBuyNowsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/buy-nows
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/buy-now/routes/post-buy-nows.ts
 */
const fetchBuyNowsByIds = ({ deployment, itemIds }: FetchBuyNowsByIdsParams) =>
    new Promise<FetchBuyNowsByIdsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'buy-nows',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ itemIds });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

// /**
//  * @category Item API
//  * @see fetchItemsByIds
//  */
// type FetchItemsByIdsParams = {
//     authToken: string;
//     deployment: string;
//     itemIds: number[];
// };

// /**
//  * @category Item API
//  * @see fetchItemsByIds
//  */
// type FetchItemsByIdsResponse = {
//     data: {
//         bidIncrements: BidIncrements[];
//         biddingInfos: BiddingInfo[];
//         buyNows: BuyNow[];
//         catalogs: Catalog[];
//         items: Item[];
//         sellers: Seller[];
//     };
//     message: string;
//     success: boolean;
// };

// /**
//  * Fetches item info by given item ids via Post to Item API service
//  * @function fetchItemsByIds
//  * @category Item API
//  * @param {FetchItemsByIdsParams}
//  * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/items
//  * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-items.ts
//  */
// const fetchItemsByIds = ({ authToken, deployment, itemIds }: FetchItemsByIdsParams) =>
//     new Promise<FetchItemsByIdsResponse>((resolve, reject) => {
//         const request = makePost({ authToken, deployment, path: '<ITEM-API>spa/small/items' });
//         request.send({ ids: itemIds });
//         request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
//     });

/**
 * @category Item API
 * @see fetchItemShippingsByIds
 */
type FetchItemShippingsByIdsParams = {
    authToken?: string;
    deployment: string;
    itemIds: number[];
};

/**
 * @category Item API
 * @see fetchItemShippingsByIds
 */
type FetchItemShippingsByIdsResponse = {
    data: ItemShipping[];
    message: string;
    success: boolean;
};

/**
 * Fetches item shipping info for given itemIds via Post to Item API Service
 * @function fetchItemShippingsByIds
 * @category Item API
 * @param {FetchItemShippingsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/item-shippings
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/shipping/routes/post-item-shippings.ts
 */
const fetchItemShippingsByIds = ({ authToken, deployment, itemIds }: FetchItemShippingsByIdsParams) =>
    new Promise<FetchItemShippingsByIdsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'item-shippings',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ itemIds });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchListingAgentsByIds
 */
type FetchListingAgentsByIdsParams = {
    agentIds: number[];
    deployment: string;
};
/**
 * @category Item API
 * @see fetchListingAgentsByIds
 */
export type ListingAgent = {
    agentId: number;
    country: string;
    state: string;
};
/**
 * @category Item API
 * @see fetchListingAgentsByIds
 */
type FetchListingAgentsByIdsResponse = {
    data: ListingAgent[];
    message: string;
    success: boolean;
};
/**
 * Fetches listing agent info by given agentIds via Post to Item API Service
 * @function fetchListingAgentsByIds
 * @category Item API
 * @param {FetchListingAgentsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/listing-agents
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/listing-agent/routes/post-listing-agents.ts
 */
const fetchListingAgentsByIds = ({ agentIds, deployment }: FetchListingAgentsByIdsParams) =>
    new Promise<FetchListingAgentsByIdsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'listing-agents',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                agentIds: agentIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchBuyNowsByIds,
    fetchItemShippingsByIds,
    fetchListingAgentsByIds,
};
