import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Search API
 * @see fetchSuggestions
 */
type FetchSuggestionsParams = {
    deployment: string;
    ipAddress: string;
    term: any;
};
/**
 * fetches search suggestions via Get to Search API
 * @function fetchSuggestions
 * @category Search API
 * @param {FetchSuggestionsParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/suggestions?{queryParams}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/ysc34s/methods/GET
 * @see https://github.com/LIVEauctioneers/search-party/tree/master/functions/SearchSuggestionsV2
 */
const fetchSuggestions = ({ deployment, ipAddress, term }: FetchSuggestionsParams) => {
    if (!term) {
        return Promise.all([]);
    }
    return new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'suggestions',
            deployment,
            ipAddress,
            path: ApiHosts.Search,
            queryParams: {
                auctionHouseQueryCount: 3,
                itemQueryCount: 6,
                term,
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};

export default {
    fetchSuggestions,
};
