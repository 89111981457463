import { ApiHosts, handleResponseOld, makeGet } from './helpers';

type fetchRelatedSearchTilesParams = {
    authToken: string;
    categoryId?: string;
    deployment: string;
    // optional on api endpoint
    itemIds?: number[];
};

type fetchRelatedSearchTilesResponse = {
    error: boolean;
    payload: [
        {
            displayName: string;
            imageUrl: string;
            relatedCategoryId: number;
            url: string;
        },
    ];
};

/**
 * Fetches related searches from categorization.relatedsearches table
 * @function fetchSuggestionsBySearchTerm
 * @category CATEGORY API
 * @param {fetchRelatedSearchTilesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/ /getrelatedsearchestiles?categoryId={}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/jvba0j/methods/GET
 */
export const fetchRelatedSearchTiles = ({
    authToken,
    categoryId,
    deployment,
    itemIds,
}: fetchRelatedSearchTilesParams) =>
    new Promise<fetchRelatedSearchTilesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getrelatedsearchestiles',
            authToken,
            deployment,
            path: ApiHosts.Category,
        });
        type QueryObject = {
            categoryId?: number;
            lotId?: string;
        };

        const queryObject: QueryObject = {
            categoryId: categoryId ? parseInt(categoryId) : undefined,
            lotId: itemIds ? itemIds.join(',') : undefined,
        };

        request.query(queryObject);

        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
