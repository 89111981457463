import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Item API
 * @see fetchSellerRecordResultsById
 */
type FetchSellerRecordResultsByIdParams = {
    deployment: string;
    sellerId: number;
};
/**
 * fetches ended catalogs by given sellerId
 * @function fetchSellerRecordResultsById
 * @category Item API
 * @param {FetchSellerRecordResultsByIdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/seller/${sellerId}/recordResults
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-seller-record-results.ts
 */
const fetchSellerRecordResultsById = ({ deployment, sellerId }: FetchSellerRecordResultsByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `spa/small/seller/${sellerId}/recordResults`,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => {
            return handleResponseOld({ err, reject, resolve, response });
        });
    });

export default {
    fetchSellerRecordResultsById,
};
