import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet } from './helpers';
import { ApprovalStatus } from '@/types/Approval';
import { PubSubConfig } from '@/types/PubSubProvider';
import type { AuctionStatus, CurrentLotStatus } from '@/types/LiveAuctionState';

/**
 * @category Clerk API
 * @see fetchLiveCatalogStatus
 */
type FetchLiveCatalogStatusParams = {
    authToken: string;
    catalogId: number;
    deployment: string;
    lotIds?: number[];
    sellerId: number;
};

type LiveCatalogLot = {
    askPrice: number;
    currentBid: number;
    endTime: string;
    isExtended: boolean;
    leadingBidderId: number;
    lotId: number;
    lotStatus: string;
};

type FetchLiveCatalogStatusResponsePayload = {
    auctionId: number;
    auctionStatus: AuctionStatus;
    bidderApproval: ApprovalStatus;
    bidderBidLimitBalance: number;
    bidderHasApprovalStatus: boolean;
    currentAskPrice: number;
    currentHighBid: number;
    currentIncrement: number;
    currentLotId: number;
    currentLotIndex: number;
    currentLotNumber: number;
    currentLotStatus: CurrentLotStatus;
    leadingBidderId: number;
    lots: LiveCatalogLot[];
    messageProvider: string;
    numberOfLotsClosed: number;
    subscriptions: { [subscriptionServiceName: string]: PubSubConfig };
};

type TransformFetchLiveCatalogStatusResponsePayload = {
    payload: FetchLiveCatalogStatusResponsePayload;
};

export type FetchLiveAuctionStatusResponseData = {
    bidder: {
        approval: ApprovalStatus;
        hasApprovalStatus: boolean;
    };
    catalogId: number;
    // the current live item for the catalog
    currentItem: {
        askPrice: number;
        increment: number;
        itemId: number;
        itemIndex: number;
        leadingBid: number;
        leadingBidder: number;
        lotNumber: number;
        lotStatus: CurrentLotStatus;
        lotsClosed?: number;
    };
    status: AuctionStatus;
    // the item that the bidder is viewing
    // this should only be populated if the user is on an item page
    viewedItem?: LiveCatalogLot;
};

export type FetchLiveAuctionStatusResponse = {
    data: FetchLiveAuctionStatusResponseData;
    error: boolean;
};

/**
 * Gets the live catalog status from auction engine
 * @function fetchLiveCatalogStatus
 * @category Clerk API
 * @param {FetchLiveCatalogStatusParams}
 * @returns {FetchLiveAuctionStatusResponse}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/clerk/houses/${sellerId}/auctions/${catalogId}/state/bidder
 */
export const fetchLiveCatalogStatus = ({
    authToken,
    catalogId,
    deployment,
    lotIds,
    sellerId,
}: FetchLiveCatalogStatusParams) =>
    new Promise<FetchLiveAuctionStatusResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/state/bidder`,
            authToken,
            deployment,
            path: ApiHosts.AuctionEngine,
            queryParams: lotIds ? { lotIds: lotIds.join(',') } : undefined,
        });
        request.end((err: any, response: any) => {
            // Handle behavior according to https://liveauctioneers.atlassian.net/browse/BUGS-3907
            // Treat 204 as 404 - Also, handle if response is null or statuscode is falsey
            if (!response?.statusCode || response?.statusCode === 204) {
                // @ts-expect-error don't want to fill in random data when only error matters
                return resolve({ error: true });
            }

            return handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
                success404: true,
                transform: ({
                    payload,
                }: TransformFetchLiveCatalogStatusResponsePayload): FetchLiveAuctionStatusResponse => {
                    const {
                        auctionStatus,
                        bidderApproval,
                        bidderHasApprovalStatus,
                        currentAskPrice,
                        currentHighBid,
                        currentIncrement,
                        currentLotId,
                        currentLotIndex,
                        currentLotNumber,
                        currentLotStatus,
                        leadingBidderId,
                        lots,
                        numberOfLotsClosed,
                    } = payload || {};

                    return {
                        data: {
                            bidder: {
                                approval: bidderApproval,
                                hasApprovalStatus: bidderHasApprovalStatus,
                            },
                            catalogId,
                            currentItem: {
                                askPrice: currentAskPrice,
                                increment: currentIncrement,
                                itemId: currentLotId,
                                itemIndex: currentLotIndex,
                                leadingBid: currentHighBid,
                                leadingBidder: leadingBidderId,
                                lotNumber: currentLotNumber,
                                lotsClosed: numberOfLotsClosed,
                                lotStatus: currentLotStatus,
                            },
                            status: auctionStatus,
                            viewedItem: lots?.length > 0 ? lots[0] : undefined,
                        },
                        error: false,
                    };
                },
            });
        });
    });

/**
 * @category Clerk API
 * @see fetchLiveCatalogStatusFromPubnub
 */
type FetchCatalogPresenceByIdParams = {
    catalogId: number;
    subscribeKey: string;
};

type FetchCatalogPresenceByIdPayload = {
    message: string;
    occupancy: number;
    service: 'Presence';
    status: number;
};

/**
 * Gets the current pubnub/ably occupancy count for a catalog
 *
 * @function fetchCatalogPresenceById
 * @category Ably/Pubnub API
 * @param {FetchCatalogPresenceByIdParams}
 * @see https://pubnub.ably.io/v2/presence/sub-key/${subscribeKey}/channel/${channel}
 * @see https://ps.pndsn.com/v2/presence/sub-key/${subscribeKey}/channel/${channel}
 */
export const fetchCatalogPresenceById = ({ catalogId, subscribeKey }: FetchCatalogPresenceByIdParams) =>
    new Promise<FetchCatalogPresenceByIdPayload>((resolve, reject) => {
        const request = makeGet({
            apiPath: `v2/presence/sub-key/${subscribeKey}/channel/catalog.${catalogId}`,
            path: ApiHosts.PubNub,
            queryParams: { disable_uuids: 1 },
            useCacheKey: false,
        });

        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response: { body: response },
                transform: ({ text }: { text: string }) => JSON.parse(text),
            })
        );
    });
