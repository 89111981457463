import { AppDispatch, GlobalState } from '@/redux/store';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Use instead of `useDispatch`
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

/**
 * Use instead of `useSelector`
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
 */
export const useAppSelector = useSelector.withTypes<GlobalState>();
