import { ApiHosts, handleResponseWithNon200Errors, makeGet } from '@/redux/api/helpers';
import { OtherCategories } from '@/types/categories/categories';

/**
 * @category Category API
 * @see fetchOtherCategories
 */
type FetchOtherCategoriesParams = {
    deployment: string;
    pageNumber: number;
    pageSize: number;
};

/**
 * @category Category API
 * @see fetchOtherCategories
 */
type FetchOtherCategoriesResponse = {
    error: boolean;
    payload: OtherCategories;
};

/**
 * @param {FetchOtherCategoriesParams}
 * @param params.deployment - environment to pull data from
 * @param params.pageNumber - which page is the user viewing
 * @param params.pageSize - how many items there are per page
 */
export const fetchOtherCategories = ({ deployment, pageNumber, pageSize }: FetchOtherCategoriesParams) =>
    new Promise<FetchOtherCategoriesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'discoversecondlevelcategories',
            deployment,
            path: ApiHosts.Category,
            queryParams: {
                page: pageNumber,
                pageSize,
            },
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
