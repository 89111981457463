import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Search API
 * @see fetchShouldDiscoverTopic
 */
type FetchShouldDiscoverTopicParams = {
    authToken: string;
    deployment: string;
    topic: string;
};

type FetchShouldDiscoverTopicResponse = {
    error?: boolean;
    errorMessage?: string;
    payload: boolean;
};
/**
 * Fetches Category descriptions by given categoryIds
 * @function fetchShouldDiscoverTopic
 * @category Search API
 * @param {FetchShouldDiscoverTopicParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/checkdiscoverpagesearchterm?searchTerm={}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/pdxwjs/methods/GET
 */
const fetchShouldDiscoverTopic = ({ authToken, deployment, topic }: FetchShouldDiscoverTopicParams) =>
    new Promise<FetchShouldDiscoverTopicResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `checkdiscoverpagesearchterm?searchTerm=${encodeURIComponent(topic)}`,
            authToken,
            deployment,
            path: ApiHosts.Search,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

// https://api-prod.liveauctioneers.com/search/checkdiscoverpagesearchterm?searchTerm=rolex

export default {
    fetchShouldDiscoverTopic,
};
