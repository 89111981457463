import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category Item API
 * @see fetchConsoleBranding
 */
type FetchConsoleBrandingResponse = {
    data: {
        consoleBrandings: {
            catalogId?: number;
            color: string;
            logoUrl: string;
            sellerId?: number;
        }[];
    };
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchConsoleBranding
 */
type FetchConsoleBrandingParams = {
    catalogId: number;
    deployment: string;
    sellerId: number;
};
/**
 * Posts seller and catalog Id to retrieve console brannding from Item API service
 * @function fetchConsoleBranding
 * @category Item API
 * @param {FetchConsoleBrandingParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/console/branding
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-console-branding.ts
 */

const fetchConsoleBranding = ({ catalogId, deployment, sellerId }: FetchConsoleBrandingParams) =>
    new Promise<FetchConsoleBrandingResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'console/branding',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({
            catalogId,
            sellerId,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchConsoleBranding,
};
