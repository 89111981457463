import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Collection API
 * @see fetchCuratedCollectionItemIDs
 */
type FetchCuratedCollectionItemIDsResponse = {
    error: boolean;
    payload: {
        id: number;
        label: string;
        lots: number[];
        name: string;
    };
};

/**
 * @category Collection API
 * @see fetchCuratedCollectionItemIDs
 */
type FetchCuratedCollectionItemIDsParams = {
    collectionID: any;
    deployment: string;
};
/**
 * Fetches curated collection items by given collectionID
 * @function fetchCuratedCollectionItemIDs
 * @category Collection API
 * @param {FetchCuratedCollectionItemIDsParams}
 * @see https://api.liveauctioneers.com/collections/getcollection/DEPLOYMENT?collection_id=${collectionID}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/6f3pbq6ef0/resources/kfqceo/methods/GET
 * @see https://github.com/LIVEauctioneers/CollectionCurationService/tree/master/functions/GetCollection
 */
const fetchCuratedCollectionItemIDs = ({ collectionID, deployment }: FetchCuratedCollectionItemIDsParams) =>
    new Promise<FetchCuratedCollectionItemIDsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getcollection',
            deployment,
            path: ApiHosts.Collection,
            queryParams: {
                collection_id: collectionID,
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchCuratedCollectionItemIDs,
};
