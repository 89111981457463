import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';
import { HouseData } from '@/types/HousePercentile';
import { ReviewComment, ReviewResponse, ReviewSubmit } from '@/types/Review';
import { WonItem } from '@/types/WonItem';

const transformSellerRating = (payload) => {
    const rating = payload.payload || {};
    return {
        [rating.houseId]: rating,
    };
};

const transformHelpfulCount = (body) => {
    if (Array.isArray(body.payload)) {
        const val = body.payload.map((item) => {
            return { [item.reviewId]: item.count };
        });
        return Object.assign({}, ...val);
    }
};

const transformReviewComments = (body) => {
    if (body.payload && Boolean(body.payload.length)) {
        const arr = { [body.payload[0].houseId]: body.payload };
        return arr;
    } else {
        return [];
    }
};

const transformBidderStatuses = (body) => {
    if (Array.isArray(body.payload)) {
        const val = body.payload.map((item) => {
            return { [item.reviewId]: item.flags };
        });
        return Object.assign({}, ...val);
    }
};

/**
 * @category Review API
 * @see fetchBidderStatuses
 */
type FetchBidderStatusesParams = {
    authToken: string;
    deployment: string;
    reviewIds: number[];
};

/**
 * @category Review API
 * @see fetchBidderStatuses
 */
type BidderStatusReviewId = {
    flags: string[]; // Not sure on this as I could not find an instance where this was filled.
    reviewId: number;
};

/**
 * @category Review API
 * @see fetchBidderStatuses
 */
type FetchBidderStatusesResponse = {
    payload: BidderStatusReviewId[];
};

/**
 * gets Review Status from Review API
 * @category Review API
 * @function fetchBidderStatuses
 * @param {FetchBidderStatusesParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-bidder-review-status
 */
const fetchBidderStatuses = ({ authToken, deployment, reviewIds }: FetchBidderStatusesParams) =>
    new Promise<FetchBidderStatusesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-bidder-review-status',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        const ids = `[${reviewIds.join(',')}]`;
        request.query({ reviewIds: ids });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformBidderStatuses,
            })
        );
    });

/**
 * @category Review API
 * @see fetchHelpfulCounts
 */
type FetchHelpfulCountsParams = {
    authToken?: string;
    deployment: string;
    reviewIds: number[];
};

/**
 * @category Review API
 * @see fetchHelpfulCounts
 */
type ReviewCountObject = {
    count: number;
    reviewId: number;
};

/**
 * @category Review API
 * @see fetchHelpfulCounts
 */
type FetchHelpfulCountsResponse = {
    payload: ReviewCountObject[];
};

/**
 * gets Helpful Counts from Review API
 * @category Review API
 * @function fetchHelpfulCounts
 * @param {FetchHelpfulCountsParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-helpful-count
 */
const fetchHelpfulCounts = ({ deployment, reviewIds }: FetchHelpfulCountsParams) =>
    new Promise<FetchHelpfulCountsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-helpful-count',
            deployment,
            path: ApiHosts.Review,
        });
        const ids = `[${reviewIds.join(',')}]`;
        request.query({ reviewIds: ids });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformHelpfulCount,
            })
        );
    });

/**
 * @category Review API
 * @see fetchHouseComments
 */
type FetchHouseCommentsParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

/**
 * @category Review API
 * @see fetchHouseComments
 */
type IndividualHouseComment = {
    bidderId: number;
    comment: string;
    commentId: number;
    commentSubmitted: string;
    commentSubmittedTS: number;
    houseId: number;
    privacyStatus: string;
    reviewId: number;
    userDisplayName: string;
    userId: number;
    userType: string;
};

/**
 * @category Review API
 * @see fetchHouseComments
 */
type FetchHouseCommentsResponse = {
    payload: IndividualHouseComment[];
};
/**
 * gets public only house comments from the Review API
 * @category Review API
 * @function fetchHouseComments
 * @param {FetchHelpfulCountsParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-house-review-comments
 */
const fetchHouseComments = ({ authToken, deployment, houseId }: FetchHouseCommentsParams) =>
    new Promise<FetchHouseCommentsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-house-review-comments',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.query({ houseId, publicOnly: true });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformReviewComments,
            })
        );
    });

/**
 * @category Review API
 * @see fetchHousePercentiles
 */
type FetchHousePercentilesParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

/**
 * @category Review API
 * @see fetchHousePercentiles
 */

/**
 * @category Review API
 * @see fetchHousePercentiles
 */
type FetchHousePercentilesResponse = {
    payload: HouseData[];
};

/**
 * gets the House Percentile parameters from the Review API
 * @category Review API
 * @function fetchHousePercentiles
 * @param {FetchHousePercentilesParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/rank
 */
const fetchHousePercentiles = ({ authToken, deployment, houseId }: FetchHousePercentilesParams) =>
    new Promise<FetchHousePercentilesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'rank',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.query({ houseId });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformSellerRating,
            })
        );
    });

/**
 * @category Review API
 * @see fetchHouseReviewData
 */
type FetchHouseReviewDataParams = {
    authToken: string;
    deployment: string;
    filter: string;
    houseId: number;
    page: number;
    pageSize: number;
    sort?: string;
};

/**
 * @category Review API
 * @see fetchHouseReviewData
 */
type FetchHouseReviewDataPayload = {
    accuracy: number;
    allowPublicReviews: number;
    filteredReviewCount: number;
    fiveStarReviews: number;
    fourStarReviews: number;
    houseId: number;
    oneStarReviews: number;
    overall: number;
    payment: number;
    responsiveness: number;
    reviews: ReviewResponse[];
    shipping: number;
    threeStarReviews: number;
    totalReviews: number;
    tsUpdated: number;
    twoStarReviews: number;
};

/**
 * @category Review API
 * @see fetchHouseReviewData
 */
type FetchHouseReviewDataResponse = {
    payload: FetchHouseReviewDataPayload;
};

/**
 * gets the house review data from the Review API
 * @category Review API
 * @function fetchHouseReviewData
 * @param {FetchHouseReviewDataParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/rating
 */
const fetchHouseReviewData = ({
    authToken,
    deployment,
    filter = '',
    houseId,
    page = 1,
    pageSize = 50,
    sort = '-overall',
}: FetchHouseReviewDataParams) =>
    new Promise<FetchHouseReviewDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'rating',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });

        request.query({ filter, houseId, page, pageSize, sort });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Review API
 * @see fetchHouseRatingData
 */
type FetchHouseRatingDataParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

/**
 * @category Review API
 * @see fetchHouseRatingData
 */
type FetchHouseRatingDataPayload = {
    accuracy: number;
    allowPublicReviews: number;
    filteredReviewCount: number;
    fiveStarReviews: number;
    fourStarReviews: number;
    houseId: number;
    oneStarReviews: number;
    overall: number;
    payment: number;
    responsiveness: number;
    reviews: ReviewResponse[];
    shipping: number;
    threeStarReviews: number;
    totalReviews: number;
    tsUpdated: number;
    twoStarReviews: number;
};

/**
 * @category Review API
 * @see fetchHouseRatingData
 */
type FetchHouseRatingDataResponse = {
    payload: FetchHouseRatingDataPayload;
};

/**
 * gets the house review data from the Review API
 * @category Review API
 * @function fetchHouseRatingData
 * @param {FetchHouseRatingDataParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-house-rating
 */
const fetchHouseRatingData = ({ authToken, deployment, houseId }: FetchHouseRatingDataParams) =>
    new Promise<FetchHouseRatingDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-house-rating',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });

        request.query({ houseId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
/**
 * @category Review API
 * @see fetchHouseLatestTopReviewsData
 */
type FetchHouseLatestTopReviewsDataParams = {
    authToken: string;
    deployment: string;
    houseId: number;
};

/**
 * @category Review API
 * @see fetchHouseLatestTopReviewsData
 */
type FetchHouseLatestTopReviewsDataPayload = {
    accuracy: number;
    allowPublicReviews: number;
    filteredReviewCount: number;
    fiveStarReviews: number;
    fourStarReviews: number;
    houseId: number;
    oneStarReviews: number;
    overall: number;
    payment: number;
    responsiveness: number;
    reviews: ReviewResponse[];
    shipping: number;
    threeStarReviews: number;
    totalReviews: number;
    tsUpdated: number;
    twoStarReviews: number;
};

/**
 * @category Review API
 * @see fetchHouseLatestTopReviewsData
 */
type FetchHouseLatestTopReviewsDataResponse = {
    payload: FetchHouseLatestTopReviewsDataPayload;
};

/**
 * gets the house review data from the Review API
 * @category Review API
 * @param {FetchHouseLatestTopReviewsDataParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-house-latest-top-reviews
 */
const fetchHouseLatestTopReviewsData = ({ authToken, deployment, houseId }: FetchHouseLatestTopReviewsDataParams) =>
    new Promise<FetchHouseLatestTopReviewsDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-house-latest-top-reviews',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });

        request.query({ houseId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Review API
 * @see fetchReviewComments
 */
type FetchReviewCommentsParams = {
    authToken: string;
    deployment: string;
    reviewId: number;
};
/**
 * @category Review API
 * @see fetchReviewComments
 */
type FetchReviewCommentsResponse = {
    payload: ReviewComment[];
};

/**
 * gets house review specific comments from the Review API
 * @category Review API
 * @function fetchReviewComments
 * @param {FetchReviewCommentsParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-house-review-comments"
 */
const fetchReviewComments = ({ authToken, deployment, reviewId }: FetchReviewCommentsParams) =>
    new Promise<FetchReviewCommentsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-house-review-comments"',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.query({ reviewId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Review API
 * @see fetchReviewData
 */
type FetchReviewDataParams = {
    authToken: string;
    bidderId: number;
    catalogIds: number[];
    deployment: string;
};

type FetchReviewDataResponse = {
    error: boolean;
    payload: [
        {
            catID: number;
            lots: number[];
            review: {
                ReviewSubmittedTS: number;
                accuracy: number;
                bidderCity: string;
                bidderCountry: string;
                bidderFirstName: string;
                bidderId: number;
                bidderLastName: string;
                bidderState: string;
                catalogId: number;
                overall: number;
                payment: number;
                responsiveness: number;
                review: string;
                reviewHeadline: string;
                reviewId: number;
                reviewSubmitted: string;
                reviewSubmittedTs: number;
                sellerId: number;
                shipping: number;
                wonItems: WonItem[];
            };
            reviewed: boolean;
        },
    ];
};

/**
 * gets Review information from the Review API
 * @category Review API
 * @function fetchReviewData
 * @param {FetchReviewDataParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-review-information
 */
const fetchReviewData = ({ authToken, bidderId, catalogIds, deployment }: FetchReviewDataParams) =>
    new Promise<FetchReviewDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-review-information',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.query({ bidderId, catalogs: `${catalogIds}` });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Review API
 * @see setBidderReviewStatus
 */
type SetReviewStatusParams = {
    authToken: string;
    deployment: string;
    reviewStatus: string;
};

/**
 * @category Review API
 * @see setBidderReviewStatus
 */
type SetReviewStatusResponse = {};
/**
 * posts bidder review status to the Review API
 * @category Review API
 * @function setBidderReviewStatus
 * @param {SetReviewStatusParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/set-review-status
 */
const setBidderReviewStatus = ({ authToken, deployment, reviewStatus }: SetReviewStatusParams) =>
    new Promise<SetReviewStatusResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'set-review-status',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.send(reviewStatus);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Review API
 * @see submitReview
 */
type SubmitReviewParams = {
    authToken: string;
    deployment: string;
    review: ReviewSubmit;
};

/**
 * @category Review API
 * @see submitReview
 * Response is always an empty object.
 */
type SubmitReviewResponse = {};

/**
 * posts review data to the Review API
 * @category Review API
 * @function submitReview
 * @param {SubmitReviewParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/create-review
 */
const submitReview = ({ authToken, deployment, review }: SubmitReviewParams) =>
    new Promise<SubmitReviewResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'create-review',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.send(review);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchBidderStatuses,
    fetchHelpfulCounts,
    fetchHouseComments,
    fetchHouseLatestTopReviewsData,
    fetchHousePercentiles,
    fetchHouseRatingData,
    fetchHouseReviewData,
    fetchReviewComments,
    fetchReviewData,
    setBidderReviewStatus,
    submitReview,
};
