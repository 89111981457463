import { ApiHosts, handleResponseOld, makePost } from './helpers';
import { BidderAddress } from '@/types/BidderAddresses';
import { ConsignmentForm } from '@/types/Consignment';

/**
 * @category Item API
 * @see postConsignment
 */
type PostConsignmentParams = {
    authToken: string;
    deployment: string;
    file: any;
    form: ConsignmentForm;
    userData: any;
};
/**
 * Post consignment image file and info to Item API service
 * @function postConsignment
 * @category Item API
 * @param {PostConsignmentParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/consignment/upload
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-consign-image-upload.ts
 */
const postConsignment = ({ authToken, deployment, file, form, userData }: PostConsignmentParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'spa/consignment/upload',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        const userDefaultAddress: BidderAddress = userData.addresses.filter((address) => Boolean(address.isDefault))[0];
        const address = `${userDefaultAddress.address1}${
            userDefaultAddress.address2 ? `${' ' + userDefaultAddress.address2}` : ''
        }`;
        request
            .field('address', address)
            .field('city', userDefaultAddress.city)
            .field('country', userDefaultAddress.countryCode)
            .field('description', form.description)
            .field('email', form.email)
            .field('multi2', form.multi2)
            .field('name', `${userData.firstName} ${userData.lastName}`)
            .field('other', form.other)
            .field('state', userDefaultAddress.state)
            .field('telephone', userData.mobileNumber)
            .attach('photo', file)
            .end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    postConsignment,
};
