import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category User API
 * @see postResellerType
 */
type PostResellerTypeParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    resellerType: number;
};
/**
 * @category User API
 * @see postResellerType
 */
type PostResellerTypeResponse = {
    error: boolean;
    payload: string;
};

/**
 * Updates user's reseller type via Post request to User API
 * @function postResellerType
 * @category User API
 * @param {PostResellerTypeParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/reseller/${bidderId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/qtw4ts/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpdateBidderReseller
 */
export default {
    postResellerType: ({ authToken, bidderId, deployment, resellerType }: PostResellerTypeParams) =>
        new Promise<PostResellerTypeResponse>((resolve, reject) => {
            const request = makePost({
                apiPath: `reseller/${bidderId}`,
                authToken,
                deployment,
                path: ApiHosts.FlynnUser,
            });
            request.send({ resellerType: resellerType });
            request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
        }),
};
