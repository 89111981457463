import { AppDispatch, GlobalState } from '@/redux/store';
import {
    AsyncThunk,
    AsyncThunkOptions,
    AsyncThunkPayloadCreator,
    createAsyncThunk as toolkitCreateThunk,
} from '@reduxjs/toolkit';

// NOTE: This is copied from redux-toolkit, because it is not exported.
// We shouldn't need to touch this often, but this will serve as a reference point, just in case
type AsyncThunkConfig = {
    /** type for `thunkApi.dispatch` */
    dispatch?: AppDispatch;
    /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
    extra?: unknown;
    /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
    fulfilledMeta?: unknown;
    /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
    pendingMeta?: unknown;
    /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
    rejectValue?: unknown;
    /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
    rejectedMeta?: unknown;
    /** return type of the `serializeError` option callback */
    serializedErrorType?: unknown;
    /** return type for `thunkApi.getState` */
    state?: unknown;
};

export type AsyncThunkBaseConfig = AsyncThunkConfig & {
    state: GlobalState;
};

export type AsyncThunkConfigWithRejectValue<ReturnedValue = Error> = AsyncThunkBaseConfig & {
    rejectValue: ReturnedValue;
};

/**
 * Wrapper function for redux-toolkit's createAsyncThunk, but typed to improve ease of use when working with rejectWithValue
 *
 * @param typePrefix Name of the action, will be appended with `.pending`, `.fulfilled`, or `.rejected` when dispatched and completed
 * @param payloadCreator The thunk function that will be run when the action is dispatched
 * @param options Additional configuration, including error-based `rejectValue` configuration
 *
 * @typeParam Returned - What the thunk will return when completed successfully
 * @typeParam Input - The arguments of the thunk that will need to be provided on dispatch
 * @typeParam Config - Additional configuration, including error-based `rejectValue` configuration
 */
export function createAsyncThunk<Returned, Input = void, Config extends AsyncThunkConfig = { state: GlobalState }>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, Input, Config>,
    options?: AsyncThunkOptions<Input, Config>
): AsyncThunk<Returned, Input, Config> {
    return toolkitCreateThunk<Returned, Input, Config>(typePrefix, payloadCreator, options);
}

interface TypeGuard<T> {
    (value: any): value is T;
}
interface HasMatchFunction<T> {
    match: TypeGuard<T>;
}

type Matcher<T> = HasMatchFunction<T> | TypeGuard<T>;

type ActionFromMatcher<M extends Matcher<any>> = M extends Matcher<infer T> ? T : never;

type AnyAsyncThunk = {
    fulfilled: {
        match: (action: any) => action is any;
    };
    pending: {
        match: (action: any) => action is any;
    };
    rejected: {
        match: (action: any) => action is any;
    };
};

export type FulfilledActionFromAsyncThunk<T extends AnyAsyncThunk> = ActionFromMatcher<T['fulfilled']>;
export type PendingActionFromAsyncThunk<T extends AnyAsyncThunk> = ActionFromMatcher<T['pending']>;
export type RejectedActionFromAsyncThunk<T extends AnyAsyncThunk> = ActionFromMatcher<T['rejected']>;
