import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category Marketplace API
 * @see postCaptureBuyNowRequest
 */
export type PostCaptureBuyNowRequestResponse = {
    error: boolean;
    payload: string;
};

/**
 * @category Marketplace API
 * @see postCaptureBuyNowRequest
 */
type PostCaptureBuyNowRequestParams = {
    authToken: string;
    deployment: string;
    details: {
        buyNowPrice: number;
        buyNowRequestType: string;
        houseId: number;
        itemId: number;
        offerPrice: number;
    };
};

/**
 * POST request to Marketplace API sends buy now request details to be captured
 * @function postCaptureBuyNowRequest
 * @category Marketplace API
 * @param {PostCaptureBuyNowRequestParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/marketplace/capturebuynowrequest
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/3uao1t5vsj/resources/i116pj/methods/POST
 */
export default {
    postCaptureBuyNowRequest: ({ authToken, deployment, details }: PostCaptureBuyNowRequestParams) =>
        new Promise<PostCaptureBuyNowRequestResponse>((resolve, reject) => {
            const request = makePost({
                apiPath: 'capturebuynowrequest',
                authToken,
                deployment,
                path: ApiHosts.MarketPlace,
            });
            request.send(details);
            request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
        }),
};
