import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Item API
 * @see fetchLotsBySellerId
 */
type FetchLotsBySellerIdParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};
/**
 * fetches storefront lots for given sellerId via Get to Item API Service
 * @function fetchLotsBySellerId
 * @category Item API
 * @param {FetchLotsBySellerIdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/sellers/${sellerId}/items
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-storefront-items.ts
 */
const fetchLotsBySellerId = ({ authToken, deployment, sellerId }: FetchLotsBySellerIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `spa/sellers/${sellerId}/items`,
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchLotsBySellerId,
};
