import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Item API
 * @see fetchSellerEndedCatalogsById
 */
type FetchSellerDetailsByIdsParams = {
    deployment: string;
    sellerIds: number[];
};

/**
 * fetches seller detail by given sellerId via Post request to Item API service
 * @function fetchSellerEndedCatalogsById
 * @category Item API
 * @param {FetchSellerEndedCatalogsByIdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/small/seller-detail
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-seller-detail.ts
 */
export const fetchSellerDetailsByIds = ({ deployment, sellerIds }: FetchSellerDetailsByIdsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'small/seller-detail',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                ids: sellerIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
