import { BuyersPremium } from '@/types/BuyersPremium';
import { LocationInfo } from '@/redux/modules/wonItems/wonItems.types';
import { ShippingMethods } from '@/enums/shippingMethod';
import { ShippingProviderId } from '@/enums/shippingProviders';

type TransformParams = {
    catalogs: any;
    count: number;
};

export const transform = ({ catalogs: response, count }: TransformParams) => {
    const catalogs = response.map((catalog) => {
        const items = catalog.results.map((item) => {
            return {
                hammerPrice: item.hammer_price,
                hammerPriceAtomic: item.hammer_price_atomic,
                itemId: item.lot_id,
                lotNumber: item.lot_num,
                title: item.title,
            };
        });

        const transformedCatalog: any = {
            atgpay: catalog.atgpay,
            buyersPremium: {
                high: catalog.buyers_premium.high,
                low: catalog.buyers_premium.low,
                lowCutoff: catalog.buyers_premium.lowCutoff,
                middle: catalog.buyers_premium.middle,
                middleCutoff: catalog.buyers_premium.middleCutoff,
            },
            catalogId: catalog.catalog_id,
            currency: catalog.currency_type.code,
            items,
            liveAuctioneersPayments: Boolean(catalog.la_payment),
            saleStartTs: catalog.sale_start_ts,
            sellerId: catalog.house_id,
            sellerName: catalog.house_name,
            title: catalog.auct_title,
        };

        if (catalog.invoice) {
            const lastPaymentReceivedTime: number | undefined = catalog.invoice.ts_payment_recieved;
            transformedCatalog.invoice = {
                balanceDue: catalog.invoice.balance_due,
                buyersPremium: catalog.invoice.buyers_premium,
                disputeReason: catalog.invoice.dispute_reason,
                hammerPrice: catalog.invoice.hammer_price,
                invoiceId: catalog.invoice.invoiceId,
                lastPaymentDate: lastPaymentReceivedTime ? new Date(lastPaymentReceivedTime * 1000) : new Date(),
                openDispute: Boolean(Number(catalog.invoice.open_dispute)), // intermittent 0, null or "0" coming in here
                paidStatus: catalog.invoice.paid_status,
                sent: Boolean(catalog.invoice.is_sent),
            };
        }

        return transformedCatalog;
    });
    return {
        data: {
            catalogs,
            count: Number(count),
        },
        message: 'Successfully retrieved the items.',
        success: true,
    };
};

export type AddressOriginationMap = {
    [addressHash: string]: Omit<LocationInfo, 'itemIds'> & { lots: number[] };
};

type TransformNewParams = {
    payload: {
        catalogs: {
            atgpay: boolean;
            auct_title: string;
            buyers_premium: BuyersPremium;
            catalog_id: number;
            currency_type: {
                code: string;
                fraction_size: string;
                html_symbol: string;
                symbol: string;
            };
            eligibleShipping: {
                catalogProviderId: ShippingProviderId;
                endOfAuction: number;
                hasExpiration: boolean;
                invoiceSentTS: number;
                isAutopay: boolean;
                isInstantAutopay: boolean;
                limitToAddShippingTs: number;
                shippingAddress: any;
                shippingAvailable: boolean;
                shippingMethod: ShippingMethods;
                shippingQuote: any;
                status: string;
            };
            house_id: number;
            house_name: string;
            invoice: {
                balance_due: number;
                buyers_premium: number;
                dispute_reason: any;
                hammer_price: number;
                invoice_total: number;
                invoiceId: number;
                is_sent: boolean;
                open_dispute: boolean;
                paid_amount: number;
                paid_status: 'paid' | 'due';
                ts_payment_recieved: number;
            };
            la_payment: number;
            lotsByOriginationAddress: AddressOriginationMap;
            results: {
                hammer_price: number;
                hammer_price_atomic: number;
                lot_id: number;
                lot_num: string;
                title: string;
            }[];
            sale_start_ts: number;
        }[];
        count: number;
    };
};

type TransformedCatalogItem = {
    hammerPrice: number;
    hammerPriceAtomic: number;
    itemId: number;
    lotNumber: string;
    title: string;
};

type TransformedCatalog = {
    atgpay: boolean;
    buyersPremium: BuyersPremium;
    catalogId: number;
    currency: string;
    eligibleShipping: {
        limitToAddDate: Date;
        quoteStatus: string;
        shippingAddress?: any;
        shippingAvailable: boolean;
        shippingQuote?: any;
    };
    invoice?: {
        balanceDue: number;
        buyersPremium: number;
        disputeReason: any;
        hammerPrice: number;
        invoiceId: number;
        invoiceTotal: number;
        lastPaymentDate: Date;
        openDispute: boolean;
        paidAmount: number;
        paidStatus: string;
        sent: boolean;
    };
    items: TransformedCatalogItem[];
    liveAuctioneersPayments: boolean;
    lotsByOriginationAddress: AddressOriginationMap;
    saleStartTs: number;
    sellerId: number;
    sellerName: string;
    title: string;
};

export const transformNew = ({ payload }: TransformNewParams) => {
    const { catalogs: response, count } = payload || {};
    const catalogs = response?.map((catalog) => {
        const items = catalog.results.map((item): TransformedCatalogItem => {
            return {
                hammerPrice: item.hammer_price,
                hammerPriceAtomic: item.hammer_price_atomic,
                itemId: item.lot_id,
                lotNumber: item.lot_num,
                title: item.title,
            };
        });

        const { buyers_premium, eligibleShipping, invoice } = catalog;

        const transformedCatalog: TransformedCatalog = {
            atgpay: catalog.atgpay,
            buyersPremium: {
                high: buyers_premium.high,
                low: buyers_premium.low,
                lowCutoff: buyers_premium.lowCutoff,
                middle: buyers_premium.middle,
                middleCutoff: buyers_premium.middleCutoff,
            },
            catalogId: catalog.catalog_id,
            currency: catalog.currency_type.code,
            eligibleShipping: undefined,
            items,
            liveAuctioneersPayments: Boolean(catalog.la_payment),
            lotsByOriginationAddress: catalog.lotsByOriginationAddress,
            saleStartTs: catalog.sale_start_ts,
            sellerId: catalog.house_id,
            sellerName: catalog.house_name,
            title: catalog.auct_title,
        };

        if (eligibleShipping) {
            transformedCatalog.eligibleShipping = {
                limitToAddDate: eligibleShipping.limitToAddShippingTs
                    ? new Date(eligibleShipping.limitToAddShippingTs * 1000)
                    : new Date(),
                quoteStatus: eligibleShipping?.status,
                shippingAvailable: eligibleShipping.shippingAvailable,
            };

            if (eligibleShipping.shippingAvailable) {
                transformedCatalog.eligibleShipping.shippingAddress = {
                    ...eligibleShipping.shippingAddress,
                };
            }

            const { shippingQuote } = eligibleShipping;
            if (shippingQuote) {
                transformedCatalog.eligibleShipping.shippingQuote = {
                    ...shippingQuote,
                };
            }
        }

        if (invoice) {
            const lastPaymentReceivedTime: number | undefined = invoice.ts_payment_recieved;
            transformedCatalog.invoice = {
                balanceDue: invoice.balance_due,
                buyersPremium: invoice.buyers_premium,
                disputeReason: invoice.dispute_reason,
                hammerPrice: invoice.hammer_price,
                invoiceId: invoice.invoiceId,
                invoiceTotal: invoice.invoice_total,
                lastPaymentDate: lastPaymentReceivedTime ? new Date(lastPaymentReceivedTime * 1000) : new Date(),
                openDispute: Boolean(Number(invoice.open_dispute)), // intermittent 0, null or "0" coming in here
                paidAmount: invoice.paid_amount,
                paidStatus: invoice.paid_status,
                sent: Boolean(invoice.is_sent),
            };
        }

        return transformedCatalog;
    });
    return {
        data: {
            catalogs,
            count: Number(count),
        },
        message: 'Successfully retrieved the items.',
        success: true,
    };
};

export default transform;
