import { ApiHosts, handleResponseOld, makeGet } from './helpers';
import { ItemFacets } from '@/types/ItemFacets';

/**
 * @category Item API
 * @see fetchItemFacets
 */
type FetchItemFacetsParams = {
    deployment: string;
    ids: number[];
};
/**
 * @category Item API
 * @see fetchItemFacets
 */
type FetchItemFacetsResponse = {
    data: ItemFacets[];
    message: string;
    success: boolean;
};
/**
 * Fetch Item facets by given ids from Item API service
 * @function fetchItemFacets
 * @category Item API
 * @param {FetchItemFacetsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/item-facets
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-item-facets.ts
 */
export const fetchItemFacets = ({ deployment, ids }: FetchItemFacetsParams) =>
    new Promise<FetchItemFacetsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/item-facets',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                ids: ids.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchItemFacets,
};
