import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';

/**
 * @category Taxes API
 * @see fetchTaxStatus
 */
type FetchTaxStatusParams = {
    authToken: string;
    deployment: string;
    invoiceId: number;
};
type FetchTaxStatusResponse = {
    error: boolean;
    payload: {
        enabled: boolean;
    };
};
/**
 * fetches tax status for given invoiceId via Get to Taxes API
 * @function fetchTaxStatus
 * @category Taxes API
 * @param {FetchTaxStatusParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/sales/invoice/{invoiceid}/enabled
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
const fetchTaxStatus = ({ authToken, deployment, invoiceId }: FetchTaxStatusParams) =>
    new Promise<FetchTaxStatusResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `sales/invoice/${invoiceId}/enabled`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Taxes API
 * @see updateTaxAddress
 */
type UpdateTaxAddressParams = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    shippingAddress: {
        address1: string;
        address2?: string;
        city: string;
        countryCode: string;
        organizationName?: string;
        phoneNumber: string;
        postalCode: string;
        state?: string;
    };
    skipProfileUpdate?: boolean;
};

type UpdateTaxAddressResponse = {
    error: boolean;
    payload: {
        success: boolean;
    };
};
/**
 * updates invoice address for tax purposes via Post to Taxes API
 * @function updateTaxAddress
 * @category Taxes API
 * @param {UpdateTaxAddressParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/taxes/sales/invoice/{invoiceid}/address
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
const updateTaxAddress = ({
    authToken,
    deployment,
    invoiceId,
    shippingAddress,
    skipProfileUpdate,
}: UpdateTaxAddressParams) =>
    new Promise<UpdateTaxAddressResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `sales/invoice/${invoiceId}/address`,
            authToken,
            deployment,
            path: ApiHosts.Taxes,
        });
        request.send({
            invoiceAddress: shippingAddress,
            invoiceId: invoiceId,
            skipProfileUpdate,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchTaxStatus,
    updateTaxAddress,
};
