import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makePost } from './helpers';

/**
 * @category User API
 * @see postForgotPassword
 */
type PostForgotPasswordParams = {
    deployment: string;
    emailAddress: string;
    sendSMS: boolean;
};

type PostForgotPasswordResponse = {
    error: boolean;
    payload: boolean;
};

/**
 * postForgotPassword - sends request for password recovery notification
 * @category User API
 * @function postForgotPassword
 * @param {PostForgotPasswordParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/forgotpassword
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/ywmkti/methods/POST
 */
export const postForgotPassword = ({ deployment, emailAddress, sendSMS }: PostForgotPasswordParams) =>
    new Promise<PostForgotPasswordResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'forgotpassword',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({ sendSMS, username: emailAddress });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see postResetPassword
 */
type PostResetPasswordParams = {
    bidderId: number;
    deployment: string;
    hash: string;
    newPassword: string;
};

/**
 * postResetPassword - sends password reset information
 * @category Item API
 * @function postResetPassword
 * @param {PostResetPasswordParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/auth/reset-password
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-reset-password.ts
 */
export const postResetPassword = ({ bidderId, deployment, hash, newPassword }: PostResetPasswordParams) =>
    new Promise((resolve, reject) => {
        // TODO: keeping this here in case we decide to use Flynn after all.
        // const request = makePost({
        //     apiPath: 'user/resetpassword',
        //     deployment,
        //     path: '<FLYNN-API>',
        // });
        const request = makePost({
            apiPath: 'resetpassword-v2',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({ bidderId, hash, newPassword });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
