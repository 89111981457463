import { ApiHosts, handleResponseOld, makeGet } from './helpers';
import { ApprovalStatus } from '@/types/Approval';

/**
 * @category Approval API
 * @see fetchBidLimitBalance
 */
type FetchBidLimitBalanceParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    itemId?: number;
};

export type BidLimitBalance = {
    approvalStatus: ApprovalStatus;
    bidLimit: number;
    bidLimitRemaining: number;
    bidderId: number;
    catalogId: number;
};

export type FetchBidLimitBalanceResponse = {
    error: boolean;
    payload: BidLimitBalance;
};

/**
 * GET request to approval API service that returns bid limits for given bidder and catalog
 * @function fetchBidLimitBalance
 * @category Approval API
 * @param {FetchBidLimitBalanceParams}
 * @returns {FetchBidLimitBalanceResponse}
 * @see https://approval-DEPLOYMENT.liveauctioneers.com/approval/catalog/${catalogId}/bidder/${bidderId}/bidLimit
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/ou2a17vn9h/resources/s5oicr/methods/GET
 * @see https://github.com/LIVEauctioneers/approval/tree/master/functions/GetBidLimitRemaining
 */
export const fetchBidLimitBalance = ({
    authToken,
    bidderId,
    catalogId,
    deployment,
    itemId,
}: FetchBidLimitBalanceParams) =>
    new Promise<FetchBidLimitBalanceResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `catalog/${catalogId}/bidder/${bidderId}/bidLimit`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });
        if (Boolean(itemId)) {
            request.query({ lotId: itemId });
        }
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
