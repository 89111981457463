import { ApiHosts, handleResponseOld, makeGet } from './helpers';
import { ItemDetail } from '@/types/item/ItemDetail';

/**
 * @category Item API
 * @see fetchItemDetailsByIds
 */
type FetchItemDetailsByIdsParams = {
    deployment: string;
    itemIds: number[];
};

/**
 * @category Item API
 * @see fetchItemDetailsByIds
 */
type FetchItemDetailsByIdsResponse = {
    data: ItemDetail[];
    message: string;
    success: boolean;
};

/**
 * Fetches Item details by given itemIds via Post to Item API service
 * @function fetchItemDetailsByIds
 * @category Item API
 * @param {FetchItemDetailsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/item-detail
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-item-detail.ts
 */
const fetchItemDetailsByIds = ({ deployment, itemIds }: FetchItemDetailsByIdsParams) =>
    new Promise<FetchItemDetailsByIdsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/item-detail',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                itemIds: itemIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export default {
    fetchItemDetailsByIds,
};
