import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';
import { Facet } from '@/types/SearchFacet';
import { ParamsOrKey } from '../modules/saveSearch';

/**
 * @category Saved Search API
 * @see fetchSavedSearches
 */
type FetchSavedSearchesParams = {
    authToken: string;
    deployment: string;
    keyword?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
};

export type SavedSearch = {
    createdDate?: string;
    facets: Facet[];
    itemImageURL: string;
    lastProcessed: string;
    lastSent: string;
    lastStatus: string;
    resultCount?: number;
    savedSearchId: number;
    searchURL: string;
    sendAlert: boolean;
    title?: string;
    userId: number;
};

export type FetchSavedSearchesResponse = {
    error: boolean;
    payload: {
        data: SavedSearch[];
        page: number;
        pageSize: number;
        totalRecords: number;
    };
};
/**
 * fetches Saved Search facets via Get request to Saved Search API
 * @function fetchSavedSearches
 * @category Saved Search API
 * @param {FetchSavedSearchesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/savedsearch/getsavedsearchfacets
 * @see https://github.com/LIVEauctioneers/savedsearch-api/blob/master/functions/GetSavedSearchFacets/main.go
 */
export const fetchSavedSearches = ({
    authToken,
    deployment,
    keyword,
    page,
    pageSize,
    sort,
}: FetchSavedSearchesParams) =>
    new Promise<FetchSavedSearchesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getsavedsearchfacets',
            authToken,
            deployment,
            path: ApiHosts.SavedSearch,
        });
        request.query({ filter: keyword, page, pageSize, sort });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see fetchSavedSearches
 */
type FetchSavedSearchItemsParams = {
    authToken: string;
    deployment: string;
    filter?: string;
    page: number;
    pageSize: number | 'all';
    sort?: string;
};

export type FetchSavedSearchItemsResponse = {
    error: boolean;
    payload: {
        data: {
            itemId: number;
            publishDate: string;
            saleStartTime: string;
        }[];
        page: number;
        pageSize: number;
        totalRecords: number;
    };
};
/**
 * returns all Saved search items via Get request to Saved Search API
 * @function fetchSavedSearchItems
 * @category Saved Search API
 * @param {FetchSavedSearchItemsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/savedsearch/getsavedsearchitems
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/511rzc/methods/GET
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/GetSavedSearchItems
 */
export const fetchSavedSearchItems = ({
    authToken,
    deployment,
    filter,
    page,
    pageSize,
    sort,
}: FetchSavedSearchItemsParams) =>
    new Promise<FetchSavedSearchItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'getsavedsearchitems',
            authToken,
            deployment,
            path: ApiHosts.SavedSearch,
        });
        request.query({ filter, page, pageSize, sort });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see deleteSavedSearchUrl
 */
type DeleteSavedSearchUrlParams = {
    deployment: string;
    savedSearchId: any;
    signature: string;
};
/**
 * removes a saved search via Post to Saved Search API
 * @function deleteSavedSearchUrl
 * @category Saved Search API
 * @param {DeleteSavedSearchUrlParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/savedsearch/deletesavedsearchurl
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/h6r6de/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/DeleteSavedSearchFromURL
 */
export const deleteSavedSearchUrl = ({ deployment, savedSearchId, signature }: DeleteSavedSearchUrlParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletesavedsearchurl',
            deployment,
            path: ApiHosts.SavedSearch,
            useCacheKey: false,
        });
        request.send({
            action: 'deleteSavedSearch',
            savedSearchId: Number(savedSearchId),
            signature,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postDeleteSavedSearch
 */
type PostDeleteSavedSearchParams = {
    authToken: string;
    deployment: string;
    savedSearchId: any;
};
/**
 * Deletes a saved search via Post to Saved Search API
 * @function postDeleteSavedSearch
 * @category Saved Search API
 * @param {PostDeleteSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/deletesavedsearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/y3zwdy/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/DeleteSavedSearch
 */
export const postDeleteSavedSearch = ({ authToken, deployment, savedSearchId }: PostDeleteSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'deletesavedsearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ savedSearchId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postIsSavedSearch
 */
type PostIsSavedSearchParams = {
    authToken: string;
    deployment: string;
    saveSearchParams: ParamsOrKey;
};
/**
 * checks a saved search resource exists via Post to Saved Search API
 * @function postIsSavedSearch
 * @category Saved Search API
 * @param {PostIsSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/getsearchsaved/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/iaoggw/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/GetSearchSaved
 */
export const postIsSavedSearch = ({ authToken, deployment, saveSearchParams }: PostIsSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getsearchsaved',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ facets: saveSearchParams });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postSaveSearch
 */
type PostSaveSearchParams = {
    authToken: string;
    deployment: string;
    saveSearchParams: ParamsOrKey;
    sendAlert: boolean;
};
/**
 * Saves a search via Post to to Saved Search API
 * @function postSaveSearch
 * @category Saved Search API
 * @param {PostSaveSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/savesearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/hikxao/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/SaveSearch
 */
export const postSaveSearch = ({ authToken, deployment, saveSearchParams, sendAlert }: PostSaveSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'savesearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ facets: saveSearchParams, sendAlert });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postRecentSearch
 */
type PostRecentSearchParams = {
    authToken: string;
    deployment: string;
    saveSearchParams: ParamsOrKey;
    url: string;
};
/**
 * Saves a recent search via Post to to Saved Search API
 * @function postRecentSearch
 * @category Saved Search API
 * @param {PostRecentSearchParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/savedsearch/saverecentsearch
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/
 */
export const postRecentSearch = ({ authToken, deployment, saveSearchParams, url }: PostRecentSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'saverecentsearch',
            authToken,
            deployment,
            path: ApiHosts.SavedSearch,
            useCacheKey: false,
        });
        request.send({ facets: saveSearchParams, url });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postSubscribeAllToSavedSearch
 */
type PostSubscribeAllToSavedSearchParams = {
    authToken: string;
    deployment: string;
};
/**
 * Subscribes to all saved search alerts via Post to Saved Search API
 * @function postSubscribeAllFromSavedSearch
 * @category Saved Search API
 * @param {PostSubscribeAllToSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/subscribesavedsearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/szkri0/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/SubscribeSavedSearch
 */
export const postSubscribeAllFromSavedSearch = ({ authToken, deployment }: PostSubscribeAllToSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'subscribesavedsearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ sendAlert: true, updateAll: true });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postSubscribeToSavedSearch
 */
type PostSubscribeToSavedSearchParams = {
    authToken: string;
    deployment: string;
    savedSearchId: any;
};
/**
 * Subscribes to a saved search alert via Post to Saved Search API
 * @function postSubscribeToSavedSearch
 * @category Saved Search API
 * @param {PostSubscribeToSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/subscribesavedsearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/szkri0/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/SubscribeSavedSearch
 */
export const postSubscribeToSavedSearch = ({
    authToken,
    deployment,
    savedSearchId,
}: PostSubscribeToSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'subscribesavedsearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ savedSearchId, sendAlert: true });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postUnsubscribeAllFromSavedSearch
 */
type PostUnsubscribeAllFromSavedSearchParams = {
    authToken: string;
    deployment: string;
};
/**
 * Unsubscribes to all saved search alerts via Post to Saved Search API
 * @function postUnsubscribeAllFromSavedSearch
 * @category Saved Search API
 * @param {PostUnsubscribeAllFromSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/subscribesavedsearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/szkri0/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/SubscribeSavedSearch
 */
export const postUnsubscribeAllFromSavedSearch = ({ authToken, deployment }: PostUnsubscribeAllFromSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'subscribesavedsearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ sendAlert: false, updateAll: true });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Saved Search API
 * @see postUnsubscribeFromSavedSearch
 */
type PostUnsubscribeFromSavedSearchParams = {
    authToken: string;
    deployment: string;
    savedSearchId: any;
};
/**
 * Unsubscribes to a saved search alert via Post to Saved Search API
 * @function postUnsubscribeFromSavedSearch
 * @category Saved Search API
 * @param {PostUnsubscribeFromSavedSearchParams}
 * @see https://api.liveauctioneers.com/savedsearch/subscribesavedsearch/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/9fwh09jse1/resources/szkri0/methods/POST
 * @see https://github.com/LIVEauctioneers/savedsearch-api/tree/master/functions/SubscribeSavedSearch
 */
export const postUnsubscribeFromSavedSearch = ({
    authToken,
    deployment,
    savedSearchId,
}: PostUnsubscribeFromSavedSearchParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'subscribesavedsearch',
            authToken,
            deployment,
            path: ApiHosts.LegacySavedSearch,
            useCacheKey: false,
        });
        request.send({ savedSearchId, sendAlert: false });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
