import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';

type AlertTypeName =
    | 'Outbid Notifications'
    | 'Auction Reminders'
    | 'Registration Updates'
    | 'Bids Ending Soon'
    | 'New Auctions From Followed Auctioneers'
    | 'Rate Your Experience';

export enum DeliveryType {
    browser = 'b',
    email = 'e',
    sms = 's',
    unsubscribe = '',
}

/**
 * @category Notifications API
 * @see fetchSmsAlerts
 */
type FetchSmsAlertsParams = {
    authToken: string;
    deployment: string;
};

type FetchSmsAlertsResponse = {
    error: boolean;
    payload: {
        alertTypeId: number;
        bidderId: number;
        deliveryType: DeliveryType;
        name: AlertTypeName;
        subscribed: boolean;
    }[];
};

/**
 * makes GET request for SMS alert settings via Telegram service
 * @function fetchSmsAlerts
 * @category Notifications API
 * @param {FetchSmsAlertsParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/alertPreferences
 */
export const fetchSmsAlerts = ({ authToken, deployment }: FetchSmsAlertsParams) =>
    new Promise<FetchSmsAlertsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'alertPreferences',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.end((err: string, response: object) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Notifications API
 * @see toggleSmsAlert
 */
type ToggleSmsAlertsParams = {
    alertTypeId: number;
    deliveryType: DeliveryType;
    subscribed: boolean;
} & FetchSmsAlertsParams;

type ToggleSmsAlertsResponse = {
    error: boolean;
    payload: {
        alertTypeId: number;
        bidderId: number;
        deliveryType: DeliveryType;
        name: AlertTypeName;
        subscribed: boolean;
    }[];
};

/**
 * makes POST request to toggle SMS alert settings via Telegram service
 * @function toggleSmsAlerts
 * @category Notifications API
 * @param {ToggleSmsAlertsParams}
 * @see https://telegram-DEPLOYMENT.liveauctioneers.com/alertPreferences
 */
export const toggleSmsAlerts = ({
    alertTypeId,
    authToken,
    deliveryType,
    deployment,
    subscribed,
}: ToggleSmsAlertsParams) =>
    new Promise<ToggleSmsAlertsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'alertPreferences',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        const query = { alertTypeId, subscribed };
        if (Boolean(deliveryType)) {
            // @ts-ignore
            query.deliveryType = deliveryType;
        }

        request.send([query]);
        request.end((err: string, response: object) => handleResponseOld({ err, reject, resolve, response }));
    });
