import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet, makePost } from './helpers';

/**
 * @category Item API
 * @see fetchFollowerCount
 */
type FetchFollowerCountParams = {
    deployment: string;
    sellerIds: number[];
};
/**
 * fetches follower count for given seller IDs via Post to Item API service
 * @function fetchFollowerCount
 * @category Item API
 * @param {FetchFollowerCountParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/follower-count
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidder/routes/post-follower-count.ts
 */
const fetchFollowerCount = ({ deployment, sellerIds }: FetchFollowerCountParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'follower-count',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                sellerIds: sellerIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchSellers
 */
type FetchSellersParams = {
    deployment: string;
};
/**
 * fetches list of all sellers via Get to Item API service
 * @function fetchSellers
 * @category Item API
 * @param {FetchSellersParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/sellers
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-all-sellers.ts
 */
const fetchSellers = ({ deployment }: FetchSellersParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/sellers',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchSellersByIds
 */
type FetchSellersByIdsParams = {
    deployment: string;
    sellerIds: number[];
};
/**
 * fetches list of sellers by given sellerIds via Post to Item API service
 * @function fetchSellersByIds
 * @category Item API
 * @param {FetchSellersByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/sellers
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-sellers.ts
 */
const fetchSellersByIds = ({ deployment, sellerIds }: FetchSellersByIdsParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/sellers',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                ids: sellerIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Personalization API
 * @see postFollow
 */
type PostFollowParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};
/**
 * follows a house for given sellerId via Post to Personalization API
 * @function postFollow
 * @category Personalization API
 * @param {PostFollowParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/personalization/followhouse
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/tq7yn802h4/resources/dw0ss8/methods/POST
 * @see https://github.com/LIVEauctioneers/personalization-api/tree/master/functions/PersonalizationFollowHouse
 */
const postFollow = ({ authToken, deployment, sellerId }: PostFollowParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'followhouse',
            authToken,
            deployment,
            path: ApiHosts.Personalization,
        });
        request.send({ houseId: sellerId });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Personalization API
 * @see postUnfollow
 */
type PostUnfollowParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};
/**
 * unfollows a house for given sellerId via Post to Personalization API
 * @function postUnfollow
 * @category Personalization API
 * @param {PostFollowParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/personalization/unfollowhouse
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/tq7yn802h4/resources/xl8itb/methods/POST
 * @see https://github.com/LIVEauctioneers/personalization-api/tree/master/functions/PersonalizationUnfollowHouse
 */
const postUnfollow = ({ authToken, deployment, sellerId }: PostUnfollowParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'unfollowhouse',
            authToken,
            deployment,
            path: ApiHosts.Personalization,
        });
        request.send({ houseId: sellerId });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

export default {
    fetchFollowerCount,
    fetchSellers,
    fetchSellersByIds,
    postFollow,
    postUnfollow,
};
