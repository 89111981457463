import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Review API
 * @see getTopRatedHouses
 */
type GetTopRatedHousesParams = {
    authToken: string;
    deployment: string;
};
/**
 * gets list of top-rated houses via Get to Review API
 * @function getTopRatedHouses
 * @category Review API
 * @param {GetTopRatedHousesParams}
 * @see https://review-service-DEPLOYMENT.liveauctioneers.com/review/get-top-rated-houses
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/2kf0hougo6/resources/4pptrq/methods/GET
 * @see https://github.com/LIVEauctioneers/reviewService/tree/master/functions/GetTopRatedHouses
 */
export const getTopRatedHouses = ({ authToken, deployment }: GetTopRatedHousesParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'get-top-rated-houses',
            authToken,
            deployment,
            path: ApiHosts.Review,
        });
        request.query({});
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
