import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';

/**
 * @category Item API
 * @see fetchEmailLists
 */
type FetchEmailListsParams = {
    authToken: string;
    deployment: string;
};

type FetchEmailListsResponse = {
    data: [
        {
            code: string;
            description: string;
            frequency: string;
            name: string;
            sequence: number;
            subscribed: boolean;
            type: string;
        },
    ];
    message: string;
    success: boolean;
};
/**
 * Fetches email lists for current profile via Get from Item API service
 * @function fetchEmailLists
 * @category Item API
 * @param {FetchEmailListsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/profile/lists
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/email/routes/get-user-email-lists.ts
 */
export const fetchEmailLists = ({ authToken, deployment }: FetchEmailListsParams) =>
    new Promise<FetchEmailListsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'profile/lists',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see postSubscribeToEmailList
 */
type PostSubscribeToEmailListParams = {
    authToken: string;
    code: string;
    deployment: string;
};

/**
 * @category Item API
 * @see postSubscribeToEmailList
 */
type PostSubscribeToEmailListResponse = {
    data: boolean;
    message: string;
    success: boolean;
};
/**
 * Subscribes user to email list for given list code via post to Item API service
 * @function postSubscribeToEmailList
 * @category Item API
 * @param {PostSubscribeToEmailListParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/profile/subscribe
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/email/routes/post-subscribe-to-email.ts
 */
export const postSubscribeToEmailList = ({ authToken, code, deployment }: PostSubscribeToEmailListParams) =>
    new Promise<PostSubscribeToEmailListResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'profile/subscribe',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ code, source: 'web' });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see postUnsubscribeFromEmailList
 */
type PostUnsubscribeFromEmailListParams = {
    authToken: string;
    code: string;
    deployment: string;
};
/**
 * @category Item API
 * @see postUnsubscribeFromEmailList
 */
type PostUnsubscribeFromEmailListResponse = {
    data: boolean;
    message: string;
    success: boolean;
};
/**
 * Unsubscribes user from given email list code via Post request to Item API service
 * @function postUnsubscribeFromEmailList
 * @category Item API
 * @param {PostUnsubscribeFromEmailListParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/profile/unsubscribe
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/email/routes/post-unsubscribe-to-email.ts
 */
export const postUnsubscribeFromEmailList = ({ authToken, code, deployment }: PostUnsubscribeFromEmailListParams) =>
    new Promise<PostUnsubscribeFromEmailListResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'profile/unsubscribe',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ code, source: 'web' });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
