import { ApiHosts, handleResponseOld, makeGet } from './helpers';

/**
 * @category Item API
 * @see fetchBidHistory
 */
type FetchBidHistoryParams = {
    authToken: string;
    deployment: string;
    itemId: number;
};

/**
 * GET request to item-api service retrieves bid history for a given item for authed user
 * @function fetchBidHistory
 * @category Item API
 * @param {FetchBidHistoryParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/bid-history/${itemId}
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidding/routes/get-bid-history-old.ts
 */

/**
 * @category Item API
 * @see fetchBidHistory
 */
type FetchBidderHistoryResponse = {
    data: [
        {
            amount: number;
            bidderId: number;
            currency: string;
            source: string;
        },
    ];
    message: string;
    success: boolean;
};

export const fetchBidHistory = ({ authToken, deployment, itemId }: FetchBidHistoryParams) =>
    new Promise<FetchBidderHistoryResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `spa/bid-history/${itemId}`,
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
