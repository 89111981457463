import { ApiHosts, handleResponseOld, makePost } from './helpers';

/**
 * @category Marketing Reporting Service
 */
type PostMarketingClickParams = {
    bidderId: number;
    deployment: string;
    eventId: number;
    eventType: string;
};

/**
 * makes POST request to marketing-reporting-service API
 * @function recordMarketingReportsClick
 * @category Marketing Reporting Service
 * @param {PostMarketingClickParams}
 */
export const recordMarketingReportsClick = ({ bidderId, deployment, eventId, eventType }: PostMarketingClickParams) => {
    return new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'recordclick',
            deployment,
            path: ApiHosts.MarketingReports,
        });
        request.send({
            BidderID: bidderId,
            EventID: eventId,
            EventType: eventType,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};

/**
 * @category Marketing Reporting Service
 */
type PostMarketingImpressionsParams = {
    bidderId: number;
    deployment: string;
    eventId: number;
    eventType: string;
};

/**
 * makes POST request to marketing-reporting-service API
 * @function recordMarketingReportsImpression
 * @category Marketing Reporting Service
 * @param {PostMarketingImpressionsParams}
 */
export const recordMarketingReportsImpression = ({
    bidderId,
    deployment,
    eventId,
    eventType,
}: PostMarketingImpressionsParams) => {
    return new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'recordimpression',
            deployment,
            path: ApiHosts.MarketingReports,
        });
        request.send({
            BidderID: bidderId,
            EventID: eventId,
            EventType: eventType,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};
