import { ApiHosts, handleResponseOld, makeGet, makePost } from './helpers';

const transformResponse = (response) => {
    if (!response || !response.payload || !response.payload.previews) {
        return { data: {} };
    }

    const catalogPreviews = Object.keys(response.payload.previews).map((id) => {
        const {
            catalogId,
            countryCode,
            description,
            houseId: sellerId,
            imagePath,
            lastModified,
            manuallyRemoved,
            previewId,
            saleStart: saleStartTs,
            title,
        } = response.payload.previews[id];

        return {
            catalogId,
            countryCode,
            description,
            imagePath,
            lastModified,
            manuallyRemoved,
            previewId,
            saleStartTs,
            sellerId,
            title,
        };
    });

    return {
        data: {
            catalogPreviews,
        },
    };
};

/**
 * @category Item API
 * @see fetchSellerEndedCatalogsById
 */
type FetchSellerEndedCatalogsByIdParams = {
    count: number;
    deployment: string;
    page: number;
    sellerId: number;
    sortTerm: string;
};
/**
 * fetches ended catalogs by given sellerId via Get to Item API service
 * @function fetchSellerEndedCatalogsById
 * @category Item API
 * @param {FetchSellerEndedCatalogsByIdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/seller/${sellerId}/recent
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-seller-recent-catalogs.ts
 */
const fetchSellerEndedCatalogsById = ({
    count,
    deployment,
    page,
    sellerId,
    sortTerm,
}: FetchSellerEndedCatalogsByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `spa/small/seller/${sellerId}/recent`,
            deployment,
            path: ApiHosts.ItemApi,
        });

        request.query({
            cursor: (page - 1) * count,
            pageSize: count,
            sortTerm,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Catalog API
 * @see fetchSellerPreviewCatalogsById
 */
type FetchSellerPreviewCatalogsByIdParams = {
    authToken: string;
    deployment: string;
    sellerId: number;
};
/**
 * fetches catalog previews by given sellerId via Post to Catalog API
 * @function fetchSellerPreviewCatalogsById
 * @category Catalog API
 * @param {FetchSellerPreviewCatalogsByIdParams}
 * @see https://api.liveauctioneers.com/catalog/previews
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/haje435b63/resources/4xkjb5/methods/POST
 */
const fetchSellerPreviewCatalogsById = ({ authToken, deployment, sellerId }: FetchSellerPreviewCatalogsByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: 'previews',
            authToken,
            deployment,
            path: ApiHosts.CatalogManagement,
        });
        request.send({ houseId: sellerId });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response: response,
                transform: transformResponse,
            })
        );
    });

/**
 * @category Item API
 * @see fetchSellerUpcomingCatalogsById
 */
type FetchSellerUpcomingCatalogsByIdParams = {
    deployment: string;
    sellerId: number;
};
/**
 * fetches upcoming catalogs by given sellerId via Get to Item API Service
 * @function fetchSellerUpcomingCatalogsById
 * @category Item API
 * @param {FetchSellerUpcomingCatalogsByIdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/seller/${sellerId}/upcoming
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-seller-upcoming-catalogs.ts
 */
const fetchSellerUpcomingCatalogsById = ({ deployment, sellerId }: FetchSellerUpcomingCatalogsByIdParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `spa/small/seller/${sellerId}/upcoming`,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
export default {
    fetchSellerEndedCatalogsById,
    fetchSellerPreviewCatalogsById,
    fetchSellerUpcomingCatalogsById,
};
