import { ApiHosts, handleResponseWithNon200Errors, makePost } from './helpers';

/**
 * @category Help Scout API
 * @see submitFeedback
 */
type SubmitFeedbackParams = {
    authToken: string;
    deployment: string;
    feedbackObject: any;
};

type SubmitFeedbackResponse = {
    error: boolean;
    payload: string;
};

export const postSubmitZendeskFeedback = ({ authToken, deployment, feedbackObject }: SubmitFeedbackParams) =>
    new Promise<SubmitFeedbackResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'feedback',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send(feedbackObject);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });

export const postSubmitZendeskGetHelp = ({ authToken, deployment, feedbackObject }: SubmitFeedbackParams) =>
    new Promise<SubmitFeedbackResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getHelp',
            authToken,
            deployment,
            path: ApiHosts.Notifications,
        });
        request.send(feedbackObject);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
